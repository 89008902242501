export default {
  palette: {
    primary: {
      light: "rgba(0 ,178, 151, 0.1)",
      main: "rgb(0 ,178, 151)",
      dark: "rgb(1, 134, 117)",
      contrastText: "#fff"
    },
    secondary: {
      main: "rgb(52, 101, 127)",
      dark: "rgb(0, 56, 93)"
    },
    error: {
      main: "rgb(236, 0, 0)"
    }
  },
  typography: {
    h1: {
      color: "rgb(0, 56, 93)"
    },
    h2: {
      color: "rgb(1, 134, 117)"
    }
  },
  overrides: {
    MuiAvatar: {
      rounded: {
        backgroundColor: "rgb(1, 134, 117)"
      }
    }
  },
  footer: {
    height: 80,
    spacing: ""
  }
};

// export default createMuiTheme({
//   palette: {
//     primary: {
//       light: "rgb(244, 249, 237)",
//       main: "rgb(140, 198, 63)",
//       dark: "rgb(98, 138, 44)"
//     },
//     secondary: {
//       light: "rgb(0, 21, 62)",
//       main: "rgb(0, 30, 90)",
//       dark: "rgb(51, 75, 123)"
//     },
//     error: {
//       main: "rgb(236, 0, 0)"
//     }
//   },
//   typography: {
//     h1: {
//       color: "rgb(255, 255, 255)"
//     },
//     h2: {
//       color: "rgb(0, 30, 90)"
//     }
//   },
//   overrides: {
//     MuiAvatar: {
//       rounded: {
//         backgroundColor: "rgb(83, 160, 180)"
//       }
//     }
//   },
//   footer: {
//     height: 80,
//     spacing: ""
//   }
// });
