import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core";
import InfoListHeader from "../InfoListHeader/InfoListHeader";
import InfoListItem from "../InfoListItem/InfoListItem";
import InfoListFooter from "../InfoListFooter/InfoListFooter";

const styles = () => ({
  InfoList: {
    marginTop: 8
  }
});

const InfoList = ({
  classes,
  title,
  headerIcon,
  hasFooter,
  personsList,
  footerBtnLabel,
  footerBtnIcon,
  showPhoto
}) => {
  return (
    <Fragment>
      <Paper elevation={0} square className={classes.InfoList}>
        <InfoListHeader icon={headerIcon} title={title} />

        {personsList.map(person => (
          <InfoListItem
            showPhoto={showPhoto}
            key={person.id}
            photo={person.photo}
            name={person.name}
            type={person.type}
            status={person.status}
            icon={person.statusProperties.icon}
            iconColor={person.statusProperties.iconColor}
            iconText={person.statusProperties.iconText}
            highlight={person.statusProperties.highlight}
            hasLink
            linkTo={person.linkTo}
          />
        ))}

        {hasFooter && (
          <InfoListFooter btnText={footerBtnLabel} btnIcon={footerBtnIcon} />
        )}
      </Paper>
    </Fragment>
  );
};

InfoList.defaultProps = {
  personsList: [],
  headerIcon: undefined,
  hasFooter: false,
  footerBtnLabel: "",
  footerBtnIcon: undefined,
  showPhoto: false
};

InfoList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  title: PropTypes.string.isRequired,
  headerIcon: PropTypes.string,
  hasFooter: PropTypes.bool,
  footerBtnLabel: PropTypes.string,
  footerBtnIcon: PropTypes.string,
  showPhoto: PropTypes.bool,
  personsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      type: PropTypes.string,
      photo: PropTypes.string,
      statusProperties: PropTypes.shape({
        icon: PropTypes.string,
        iconColor: PropTypes.string,
        iconText: PropTypes.string,
        highlight: PropTypes.bool
      })
    })
  )
};

export default withStyles(styles, { withTheme: true })(InfoList);
