import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import PropTypes from "prop-types";
import FormLabel from "../../form/FormLabel/FormLabel";

const RadioInput = ({ field, form, form: { touched, errors }, ...props }) => {
  const { fieldOptions: properties, value } = props;

  const handleChange = async e => {
    await props.onChange(e);
    props.onBlur();
  };

  return (
    <React.Fragment>
      {properties.label && <FormLabel text={properties.label} />}

      <RadioGroup
        name={properties.name}
        value={value || ""}
        onChange={handleChange}
        style={{ color: "rgba(0,0,0,.5)" }}
        row
      >
        {properties.options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.description}
          />
        ))}
      </RadioGroup>

      {touched[properties.name] && Boolean(errors[properties.name]) && (
        <FormHelperText className="Mui-error">
          {errors[properties.name]}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

/**
 * A estrutura das propriedas permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
RadioInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default RadioInput;
