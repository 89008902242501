import React, { memo } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Field } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Ajv from "ajv";
import { blurField, validateField } from "../../../redux/actions";
import TextInput from "../../field/TextInput/TextInput";
import RadioInput from "../../field/RadioInput/RadioInput";
import CheckInput from "../../field/CheckInput/CheckInput";
import SelectInput from "../../field/SelectInput/SelectInput";
import DateInput from "../../field/DateInput/DateInput";
import TimeInput from "../../field/TimeInput/TimeInput";
import FileInput from "../../field/FileInput/FileInput";
import DataTable from "../../field/DataTable/DataTable";
import FetchSelect from "../../field/FetchSelect/FetchSelect";
import ContractInput from "../../field/ContractInput/ContractInput";
import Service from "../../../AppService";
import Utils from "../../../utils/waybeeUtils";
import AjvUtils from "../../../utils/ajvUtils";

const ajv = new Ajv({ allErrors: true, jsonPointers: true, $data: true }); // options can be passed, e.g. {allErrors: true}
AjvUtils.setCustomKeywords(ajv);
require("ajv-errors")(ajv);

const FormField = props => {
  const {
    field,
    handleChange,
    setFieldTouched,
    setFieldError,
    renderInfo,
    blurField: onBlurField,
    validateField: fieldValidation,
    storedValues
  } = props;

  let { value, touched } = props;

  const renderValidation = (renderProperties, text) => {
    if (!renderProperties) return null;
    renderProperties.forEach(renderSchema => {
      const validate = ajv.compile(renderSchema.renderIf);
      const valid = validate(text);
      const componentName = renderSchema.type + renderSchema.id;
      fieldValidation({ [componentName]: valid });
    });
    return null;
  };

  const onValidateField = properties => {
    renderValidation(properties.render, value);
    const schema = properties.validations;
    if (!schema) return "";

    const validate = ajv.compile(schema);
    const valid = validate(value);
    let msg = "";

    if (!valid) {
      AjvUtils.getErrorsMessage(validate.errors);
      validate.errors.forEach(erro => {
        msg += `${Utils.capitalize(erro.message)}. `;
      });
    }

    return msg;
  };

  const onChange = (name, e) => {
    if (e.persist) e.persist();
    // Usando alias para desconstruct
    const { value: changedValue } = e.target;
    value = changedValue;
    handleChange(e);
    touched = true;
    setFieldTouched(name, true, false);
  };

  const onBlur = async fieldName => {
    if (!touched) return;
    try {
      const fieldObj = { [fieldName]: value };
      onBlurField(fieldObj);
      await Service.saveField(fieldObj);
    } catch (e) {
      setFieldError(fieldName, "Ocorreu um erro ao salvar o campo");
      console.error(e);
      onBlurField({ [fieldName]: undefined });
    }
  };

  const renderInput = () => {
    const { type } = field;

    const textsInputTypes = ["text", "number", "textarea", "email", "password"];
    const radioInputFields = ["radio"];
    const checkInputFields = ["check"];
    const selectInputFields = ["select"];
    const dateInputFields = ["date", "datetime"];
    const timeInputFields = ["time"];
    const fileInputFields = ["file"];
    const dataTable = ["table"];
    const fetchSelect = ["fetch-select"];
    const contractInputType = ["contract"];

    if (textsInputTypes.includes(type)) return TextInput;
    if (radioInputFields.includes(type)) return RadioInput;
    if (checkInputFields.includes(type)) return CheckInput;
    if (selectInputFields.includes(type)) return SelectInput;
    if (dateInputFields.includes(type)) return DateInput;
    if (timeInputFields.includes(type)) return TimeInput;
    if (fileInputFields.includes(type)) return FileInput;
    if (dataTable.includes(type)) return DataTable;
    if (fetchSelect.includes(type)) return FetchSelect;
    if (contractInputType.includes(type)) return ContractInput;

    return TextInput;
  };

  const getGrid = () => {
    const { type, grid } = field;
    if (grid) return grid;
    if (type === "check" || type === "table") return { xs: 12 };
    if (type === "file") return { lg: 3, sm: 6, xs: 12 };
    return {
      xs: 12,
      sm: 6
    };
  };

  const fieldName = `field${field.id}`;
  field.name = fieldName;

  const required = field.validations && field.validations.notNull;
  if (required) {
    if (
      field.label.substr(field.label.length - 1, field.label.length) !== "*"
    ) {
      field.label += " *";
    }
  }

  if (!storedValues[fieldName] && value) {
    onBlurField({ [fieldName]: value });
  }

  const getValue = () => {
    if (touched) {
      return value;
    }
    return storedValues[fieldName] || field.value;
  };

  return (
    <Grid
      item
      {...getGrid()}
      component="div"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      {!(field.conditional && !renderInfo[fieldName]) ? (
        <div style={{ width: "100%" }}>
          <Field
            name={fieldName}
            component={renderInput()}
            onChange={e => onChange(fieldName, e)}
            onBlur={() => onBlur(fieldName)}
            validateOnBlur
            validate={() => onValidateField(field)}
            fieldOptions={field}
            value={getValue()}
          />
        </div>
      ) : (
        <span />
      )}
    </Grid>
  );
};

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
FormField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  validateField: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  renderInfo: PropTypes.object.isRequired,
  blurField: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  storedValues: PropTypes.object.isRequired,
  touched: PropTypes.bool
};

FormField.defaultProps = {
  value: "",
  touched: false
};

const mapStateToProps = store => ({
  storedValues: store.jsonFormState,
  renderInfo: store.renderInfoState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ blurField, validateField }, dispatch);

const areEqual = (prevProps, nextProps) => {
  return (
    !nextProps.field.conditional &&
    prevProps.value === nextProps.value &&
    prevProps.field === nextProps.field
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(FormField, areEqual));
