import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import {
  Container,
  Button,
  Grid,
  withStyles,
  Popover
} from "@material-ui/core";
import { Warning as WarningIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import Ajv from "ajv";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import PageHeader from "../../components/page/PageHeader/PageHeader";
import PageTitle from "../../components/page/PageTitle/PageTitle";
import NavigationBack from "../../components/page/NavigationBack/NavigationBack";
import FormGroup from "../../components/form/FormGroup/FormGroup";
import WorkflowService from "./WorkflowService";
import Alert from "../../components/alert/Alert/Alert";
import AjvUtils from "../../utils/ajvUtils";
import Utils from "../../utils/waybeeUtils";
import Service from "../FormGroupPage/FormGroupService";
import AppService from "../../AppService";
import { saveJsonForm } from "../../redux/actions";

const ajv = new Ajv({ allErrors: true, jsonPointers: true, $data: true }); // options can be passed, e.g. {allErrors: true}
AjvUtils.setCustomKeywords(ajv);
require("ajv-errors")(ajv);

const styles = theme => ({
  WorkflowPage: {
    [theme.breakpoints.up("sm")]: {
      margin: 20
    }
  },
  Container: {
    padding: 0
  },
  ButtonContainer: {
    textAlign: "center",
    padding: `70px 24px ${
      theme.footer && theme.footer.spacing ? theme.footer.spacing : 130
    }px 24px`
  },
  Button: {
    textTransform: "none",
    maxWidth: "100%",
    height: 44,
    fontSize: 16,
    color: "white"
  },
  Popover: {
    pointerEvents: "none"
  },
  Paper: {
    padding: theme.spacing(1),
    whiteSpace: "pre-wrap",
    maxWidth: 500
  },
  WarningIcon: {
    marginLeft: 15
  }
});

class WorkflowPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInfos: {},
      redirectTo: undefined,
      openDialog: false,
      dialogMsg: "",
      dialogTitle: "",
      openErrorMsg: false,
      popoverAchor: null
    };
  }

  componentWillMount() {
    const formInfo = this.findForm();
    this.setState({ formInfos: formInfo });
  }

  onAddSubgroup = async group => {
    // eslint-disable-next-line react/prop-types
    const { saveJsonForm: dispatchJsonForm, jsonForm } = this.props;
    try {
      const res = await Service.addSubgroup(group.id);
      const newSubgroups = res.data.map(subgroup => {
        const newSubgroup = subgroup;
        newSubgroup.replicable = true;
        return newSubgroup;
      });
      group.subgroups = group.subgroups.concat(newSubgroups); // eslint-disable-line no-param-reassign
      dispatchJsonForm(jsonForm);
    } catch (e) {
      AppService.notifyError(e);
      console.error(e);
      this.openAlert(
        "error",
        "Erro",
        "Ocorreu um erro ao adicionar formulário."
      );
    }
  };

  onRemoveSubgroup = async (group, subgroupId) => {
    // eslint-disable-next-line react/prop-types
    const { saveJsonForm: dispatchJsonForm, jsonForm } = this.props;
    try {
      await Service.removeSubgroup(subgroupId);
      _.remove(group.subgroups, subgroup => subgroup.id === subgroupId); // eslint-disable-line no-param-reassign
      dispatchJsonForm(jsonForm);
    } catch (e) {
      AppService.notifyError(e);
      console.error(e);
      this.openAlert(
        "error",
        "Erro",
        "Ocorreu um erro ao adicionar formulário."
      );
    }
  };

  findForm = nextStep => {
    const { match } = this.props;
    const { idGroup } = match.params;
    const foundItem = this.findFormGroup();
    this.setState({ workflow: foundItem });

    if (foundItem) {
      if (foundItem.workflow) {
        const foundWorkflow = foundItem.workflow.find(
          wf =>
            wf.step.toString() === (nextStep || foundItem.lastStep.toString())
        );
        if (foundWorkflow) return foundWorkflow;
        this.setState({ redirectTo: `/contract/${idGroup}` });
      }
    }
    return null;
  };

  findFormGroup = () => {
    const { jsonForm, match } = this.props;
    const { idGroup } = match.params;

    let foundItem = jsonForm.formContract.find(
      form => form.id.toString() === idGroup
    );
    if (!foundItem) {
      foundItem = jsonForm.formResponsible.find(
        form => form.id.toString() === idGroup
      );
    }
    return foundItem;
  };

  handleClick = async button => {
    const { history, match } = this.props;
    const { formInfos } = this.state;
    const foundItem = this.findFormGroup();

    console.log(formInfos);
    if (
      foundItem.workflow[foundItem.workflow.length - 1].step === formInfos.step
    )
      history.push(`/contract/${match.params.idGroup}`);

    if (button.goTo === 0) {
      history.push("/home");
    } else {
      try {
        await WorkflowService.nextStep(
          button.id,
          match.params.idGroup,
          formInfos.id,
          formInfos.step
        );
        foundItem.lastStep = button.goTo;
        this.setState({ formInfos: this.findForm(button.goTo.toString()) });
      } catch (e) {
        console.error(e);
        if (e.response.data && e.response.data.error) {
          const { error } = e.response.data;

          let errorMsg = "";
          if (error.errors && error.errors.length) {
            error.errors.forEach(err => {
              errorMsg += `${err.message}\n`;
            });
          } else {
            errorMsg = error.message;
          }
          this.setState({
            openDialog: true,
            dialogMsg: errorMsg,
            dialogTitle: "Ocorreu um erro"
          });
        }
      }
    }
  };

  isDisabled = button => {
    const { renderInfo } = this.props;

    if (button.useInternalValidation) {
      return !renderInfo[`button${button.id}`];
    }
    const errorMessage = this.getErrorMessage(button);
    if (errorMessage) return !!errorMessage;

    return false;
  };

  getErrorMessage = button => {
    const { storedValues } = this.props;
    const errorMsg = [];
    if (button.validations && button.validations.length) {
      button.validations.forEach(validation => {
        const msg = this.validateField(
          validation,
          storedValues[`field${validation.fieldId}`]
        );
        if (msg) errorMsg.push(msg);
      });
    }
    return errorMsg.length ? errorMsg : undefined;
  };

  validateField = (schema, value) => {
    const validate = ajv.compile(schema);
    const valid = validate(value);
    let msg = "";

    if (!valid) {
      AjvUtils.getErrorsMessage(validate.errors);
      validate.errors.forEach(erro => {
        msg += `${Utils.capitalize(erro.message)}. `;
      });
    }

    return msg;
  };

  onHoverButton = (event, open, errorMsg) => {
    if (errorMsg)
      this.setState({ openErrorMsg: open, popoverAchor: event.target });
  };

  render() {
    const { classes, schoolData } = this.props;
    const {
      formInfos,
      redirectTo,
      workflow,
      openDialog,
      dialogMsg,
      dialogTitle,
      openErrorMsg,
      popoverAchor
    } = this.state;
    const { buttons } = formInfos;
    if (redirectTo) return <Redirect to={redirectTo} />;
    console.log("aaaa", buttons);
    return (
      <Fragment>
        <PageHeader logo={schoolData.logo} />
        <PageTitle title={workflow.name} hasPhoto photo={workflow.photo} />
        <NavigationBack backTo="/home" />
        <Container maxWidth="lg" className={classes.Container}>
          <div className={`WorkflowPage-root ${classes.WorkflowPage}`}>
            {formInfos.groups.map(group => (
              <FormGroup
                key={group.id}
                {...group}
                onAddSubgroup={async () => this.onAddSubgroup(group)}
                onRemoveSubgroup={subgroupId =>
                  this.onRemoveSubgroup(group, subgroupId)
                }
              />
            ))}
          </div>
        </Container>

        <div className={`ButtonContainer-root ${classes.ButtonContainer}`}>
          <Grid container spacing={2} justify="center">
            {buttons &&
              buttons.map(btn => {
                if (!btn.show) return null;
                const disabled = this.isDisabled(btn);
                const errorMsg = this.getErrorMessage(btn);

                return (
                  <Grid item xs={12} sm={6} {...btn.grid}>
                    <div
                      onMouseEnter={e => this.onHoverButton(e, true, errorMsg)}
                      onMouseLeave={e => this.onHoverButton(e, false, errorMsg)}
                    >
                      <Button
                        className={`Button-root ${classes.Button}`}
                        fullWidth
                        href="#"
                        variant="contained"
                        color={disabled ? undefined : btn.color}
                        disabled={disabled}
                        onClick={() => this.handleClick(btn)}
                      >
                        {btn.label}
                        {errorMsg ? (
                          <WarningIcon className={classes.WarningIcon} />
                        ) : null}
                      </Button>
                      {errorMsg && (
                        <Popover
                          open={openErrorMsg}
                          anchorEl={popoverAchor}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left"
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                          }}
                          className={classes.Popover}
                          classes={{
                            paper: classes.Paper
                          }}
                        >
                          {errorMsg.map(msg => (
                            <ul>
                              <Typography variant="body1">
                                <li>{msg}</li>
                              </Typography>
                            </ul>
                          ))}
                        </Popover>
                      )}
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </div>

        <Alert
          open={openDialog}
          msg={dialogMsg}
          status="error"
          title={dialogTitle}
          type="swal"
          onClose={() => this.setState({ openDialog: false })}
        />
      </Fragment>
    );
  }
}

WorkflowPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  jsonForm: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  renderInfo: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  storedValues: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // https://reacttraining.com/react-router/web/example/url-params
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired
};

const mapStateToProps = store => ({
  jsonForm: store.jsonForm,
  schoolData: store.schoolData,
  renderInfo: store.renderInfoState,
  storedValues: store.jsonFormState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveJsonForm }, dispatch);

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(WorkflowPage);
