const mock = {
  id: "b8b67a57-5fdd-4244-99d0-d661cb589580",
  title: "Matrícula 2019",
  formResponsible: [
    {
      id: 1,
      name: "Marcos José Barbosa",
      type: "Responsável Pedagógico",
      photo: "https://waybee.s3.amazonaws.com/img/pai-min.png",
      status: "PENDING",
      forms: [
        {
          id: 1,
          name: "Informações Gerais",
          icon: "settings",
          status: "PENDING",
          groups: [
            {
              id: 1,
              title: "Informações Gerais",
              icon: "settings",
              subgroups: [
                {
                  id: 1,
                  fields: [
                    {
                      id: 1,
                      type: "text",
                      label: "Nome Completo",
                      value: "Marcos José Barbosa",
                      validations: {
                        type: "string",
                        minLength: 5,
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 2,
                  fields: [
                    {
                      id: 2,
                      type: "date",
                      label: "Data de Nascimento",
                      value: "27/05/1980",
                      validations: {
                        type: "string",
                        notNull: true
                      }
                    },
                    {
                      id: 3,
                      type: "text",
                      label: "Nacionalidade",
                      validations: {
                        type: "string",
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 3,
                  fields: [
                    {
                      id: 4,
                      type: "radio",
                      label: "Sexo",
                      value: "M",
                      options: [
                        {
                          value: "F",
                          description: "Feminino"
                        },
                        {
                          value: "M",
                          description: "Masculino"
                        }
                      ]
                    },
                    {
                      id: 5,
                      type: "text",
                      label: "Religião",
                      validations: {
                        type: "string",
                        minLength: 1
                      }
                    }
                  ]
                },
                {
                  id: 4,
                  fields: [
                    {
                      id: 6,
                      type: "radio",
                      label: "Falecido?",
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 2,
              title: "Documentos",
              icon: "description",
              subgroups: [
                {
                  id: 5,
                  fields: [
                    {
                      id: 7,
                      type: "text",
                      label: "RG/RNE",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 8,
                      type: "text",
                      label: "Orgão Emissor",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 9,
                      type: "text",
                      label: "UF",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 6,
                  fields: [
                    {
                      id: 10,
                      type: "text",
                      label: "CPF",
                      value: "44724328894",
                      mask: "000.000.000-00",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 7,
                  fields: [
                    {
                      id: 11,
                      type: "file",
                      label: "cópia do RG",
                      status: "NOT_SENT"
                    },
                    {
                      id: 12,
                      type: "file",
                      label: "cópia do CPF",
                      status: "NOT_SENT"
                    }
                  ]
                }
              ]
            },
            {
              id: 3,
              subgroups: [
                {
                  id: 8,
                  fields: [
                    {
                      id: 13,
                      type: "radio",
                      label: "Foi Aluno do Colégio Albert Sabin?",
                      render: [
                        {
                          id: 113,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 14,
                      conditional: true,
                      type: "text",
                      label: "Quando?"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 2,
          name: "Endereço e Contato",
          icon: "room",
          status: "PENDING",
          groups: [
            {
              id: 4,
              title: "Endereço e Contato",
              icon: "room",
              subgroups: [
                {
                  id: 9,
                  fields: [
                    {
                      id: 15,
                      type: "text",
                      label: "Endereço",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 16,
                      type: "number",
                      label: "Número",
                      validations: {
                        type: "number"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 17,
                      type: "text",
                      label: "Complemento",
                      validations: {
                        type: "string"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 18,
                      type: "text",
                      label: "CEP",
                      mask: "00000-000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 19,
                      type: "text",
                      label: "Bairro",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 20,
                      type: "text",
                      label: "Cidade",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 21,
                      type: "text",
                      label: "Estado",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 10,
                  fields: [
                    {
                      id: 22,
                      type: "text",
                      label: "Telefone Residencial",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 23,
                      type: "text",
                      label: "Telefone Celular",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 3,
          name: "Informações Profissionais",
          icon: "work",
          status: "PENDING",
          groups: [
            {
              id: 5,
              title: "Informações Profissionais",
              icon: "work",
              subgroups: [
                {
                  id: 11,
                  fields: [
                    {
                      id: 24,
                      label: "Profissão",
                      type: "text"
                    },
                    {
                      id: 25,
                      label: "Empresa",
                      type: "text"
                    },
                    {
                      id: 26,
                      label: "Cargo",
                      type: "text"
                    }
                  ]
                }
              ]
            },
            {
              id: 6,
              title: "Endereço Profissional",
              icon: "room",
              subgroups: [
                {
                  id: 12,
                  fields: [
                    {
                      id: 27,
                      type: "text",
                      label: "Endereço comercial",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 28,
                      type: "number",
                      label: "Número",
                      validations: {
                        type: "number"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 29,
                      type: "text",
                      label: "Complemento",
                      validations: {
                        type: "string"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 30,
                      type: "text",
                      label: "CEP",
                      mask: "00000-000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 31,
                      type: "text",
                      label: "Bairro",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 32,
                      type: "text",
                      label: "Cidade",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 33,
                      type: "text",
                      label: "Estado",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 13,
                  fields: [
                    {
                      id: 34,
                      type: "text",
                      label: "Telefone Comercial",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 35,
                      type: "email",
                      label: "E-mail Comercial",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 2,
      name: "Amanda Lima",
      type: "Responsável Financeiro",
      photo: "https://waybee.s3.amazonaws.com/img/ma%CC%83e+(admin)-min.png",
      status: "COMPLETED",
      forms: [
        {
          id: 4,
          name: "Informações Gerais",
          icon: "settings",
          status: "COMPLETED",
          groups: [
            {
              id: 7,
              title: "Informações Gerais",
              icon: "settings",
              subgroups: [
                {
                  id: 14,
                  fields: [
                    {
                      id: 36,
                      type: "text",
                      label: "Nome Completo",
                      value: "Amanda Lima",
                      validations: {
                        type: "string",
                        minLength: 5,
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 15,
                  fields: [
                    {
                      id: 37,
                      type: "date",
                      label: "Data de Nascimento",
                      value: "23/08/1980",
                      validations: {
                        type: "string",
                        notNull: true
                      }
                    },
                    {
                      id: 38,
                      type: "text",
                      label: "Nacionalidade",
                      value: "Brasileiro",
                      validations: {
                        type: "string",
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 16,
                  fields: [
                    {
                      id: 39,
                      type: "radio",
                      label: "Sexo",
                      value: "F",
                      options: [
                        {
                          value: "F",
                          description: "Feminino"
                        },
                        {
                          value: "M",
                          description: "Masculino"
                        }
                      ]
                    },
                    {
                      id: 40,
                      type: "text",
                      label: "Religião",
                      validations: {
                        type: "string",
                        minLength: 1
                      }
                    }
                  ]
                },
                {
                  id: 17,
                  fields: [
                    {
                      id: 41,
                      type: "radio",
                      label: "Falecido?",
                      value: "N",
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 8,
              title: "Documentos",
              icon: "description",
              subgroups: [
                {
                  id: 18,
                  fields: [
                    {
                      id: 42,
                      type: "text",
                      label: "RG/RNE",
                      value: "39087134X",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 43,
                      type: "text",
                      label: "Orgão Emissor",
                      value: "SSP",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 44,
                      type: "text",
                      label: "UF",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 19,
                  fields: [
                    {
                      id: 45,
                      type: "text",
                      label: "CPF",
                      value: "44724328894",
                      mask: "000.000.000-00",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 20,
                  fields: [
                    {
                      id: 46,
                      type: "file",
                      label: "cópia do RG",
                      status: "APPROVED",
                      manualValidation: true,
                      url:
                        "https://storage.googleapis.com/waybee-development/enrollments/images%20(1).jpeg"
                    },
                    {
                      id: 47,
                      type: "file",
                      label: "cópia do CPF",
                      status: "APPROVED",
                      manualValidation: true,
                      url:
                        "https://storage.googleapis.com/waybee-development/enrollments/images%20(1).jpeg"
                    }
                  ]
                }
              ]
            },
            {
              id: 9,
              subgroups: [
                {
                  id: 21,
                  fields: [
                    {
                      id: 48,
                      type: "radio",
                      label: "Foi Aluno do Colégio Albert Sabin?",
                      value: "N",
                      render: [
                        {
                          id: 49,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 49,
                      conditional: true,
                      type: "text",
                      label: "Quando?"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 5,
          name: "Endereço e Contato",
          icon: "room",
          status: "COMPLETED",
          groups: [
            {
              id: 10,
              title: "Endereço e Contato",
              icon: "room",
              subgroups: [
                {
                  id: 22,
                  fields: [
                    {
                      id: 50,
                      type: "text",
                      label: "Endereço Residencial",
                      value: "Rua Augusta",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 51,
                      type: "number",
                      label: "Número",
                      value: 2516,
                      validations: {
                        type: "number"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 52,
                      type: "text",
                      label: "Complemento",
                      value: "Cj 142",
                      validations: {
                        type: "string"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 53,
                      type: "text",
                      label: "CEP",
                      mask: "00000-000",
                      value: "02223010",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 54,
                      type: "text",
                      label: "Bairro",
                      value: "Cerqueira César",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 55,
                      type: "text",
                      label: "Cidade",
                      value: "São Paulo",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 56,
                      type: "text",
                      label: "Estado",
                      value: "SP",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 23,
                  fields: [
                    {
                      id: 57,
                      type: "text",
                      label: "Telefone Residencial",
                      value: "11987263523",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 58,
                      type: "text",
                      label: "Telefone Celular",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 6,
          name: "Informações Profissionais",
          icon: "work",
          status: "COMPLETED",
          groups: [
            {
              id: 11,
              title: "Informações Profissionais",
              icon: "work",
              subgroups: [
                {
                  id: 24,
                  fields: [
                    {
                      id: 59,
                      label: "Profissão",
                      type: "text",
                      value: "Advogada"
                    },
                    {
                      id: 60,
                      label: "Empresa",
                      type: "text",
                      value: "Advocacia"
                    },
                    {
                      id: 61,
                      label: "Cargo",
                      type: "text",
                      value: "Advogada Sr"
                    }
                  ]
                }
              ]
            },
            {
              id: 12,
              title: "Endereço Profissional",
              icon: "room",
              subgroups: [
                {
                  id: 25,
                  fields: [
                    {
                      id: 62,
                      type: "text",
                      label: "Endereço comercial",
                      value: "Rua Augusta",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 63,
                      type: "number",
                      label: "Número",
                      value: 2817,
                      validations: {
                        type: "number"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 64,
                      type: "text",
                      label: "Complemento",
                      value: "Cj 122",
                      validations: {
                        type: "string"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 65,
                      type: "text",
                      label: "CEP",
                      value: "09912010",
                      mask: "00000-000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 66,
                      type: "text",
                      label: "Bairro",
                      value: "Jardim América",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 67,
                      type: "text",
                      label: "Cidade",
                      value: "São Paulo",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 68,
                      type: "text",
                      label: "Estado",
                      value: "SP",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 26,
                  fields: [
                    {
                      id: 69,
                      type: "text",
                      label: "Telefone Comercial",
                      value: "11987253442",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 70,
                      type: "email",
                      label: "E-mail Comercial",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  formStudent: [
    {
      id: 3,
      name: "Gabriel Lima",
      type: "Aluno",
      status: "ERROR",
      forms: [
        {
          id: 9,
          name: "Informações Gerais",
          icon: "settings",
          status: "COMPLETED",
          groups: [
            {
              id: 13,
              title: "Informações Gerais",
              icon: "settings",
              subgroups: [
                {
                  id: 27,
                  fields: [
                    {
                      id: 71,
                      type: "text",
                      label: "Nome Completo",
                      value: "Gabriel Lima",
                      validations: {
                        type: "string",
                        minLength: 5,
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 28,
                  fields: [
                    {
                      id: 72,
                      type: "date",
                      label: "Data de Nascimento",
                      value: "10/05/2006",
                      validations: {
                        type: "string",
                        notNull: true
                      }
                    },
                    {
                      id: 73,
                      type: "select",
                      label: "País de nascimento",
                      value: "BR",
                      options: [
                        {
                          value: "BR",
                          description: "Brasil"
                        },
                        {
                          value: "US",
                          description: "Estados Unidos"
                        },
                        {
                          value: "CA",
                          description: "Canadá"
                        },
                        {
                          value: "UK",
                          description: "Reino Unido"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 74,
                      type: "select",
                      label: "Estado",
                      value: "SP",
                      options: [
                        {
                          value: "SP",
                          description: "São Paulo"
                        },
                        {
                          value: "RJ",
                          description: "Rio de Janeiro"
                        },
                        {
                          value: "ES",
                          description: "Espírito Santo"
                        },
                        {
                          value: "MG",
                          description: "Minas Gerais"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 75,
                      type: "select",
                      label: "Cidade",
                      value: 1,
                      options: [
                        {
                          value: 1,
                          description: "São Paulo"
                        },
                        {
                          value: 2,
                          description: "Rio de Janeiro"
                        },
                        {
                          value: 3,
                          description: "Vitória"
                        },
                        {
                          value: 4,
                          description: "Belo Horizonte"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 29,
                  fields: [
                    {
                      id: 76,
                      type: "radio",
                      label: "Sexo",
                      value: "M",
                      options: [
                        {
                          value: "F",
                          description: "Feminino"
                        },
                        {
                          value: "M",
                          description: "Masculino"
                        }
                      ],
                      grid: {
                        xs: 12
                      }
                    },
                    {
                      id: 77,
                      type: "select",
                      label: "Cor / Raça (p/ Censo Escolar IBGE)",
                      value: 1,
                      options: [
                        {
                          value: 1,
                          description: "Branco"
                        },
                        {
                          value: 2,
                          description: "Preto"
                        },
                        {
                          value: 3,
                          description: "Pardo"
                        },
                        {
                          value: 4,
                          description: "Amarelo"
                        },
                        {
                          value: 5,
                          description: "Indígena"
                        },
                        {
                          value: 6,
                          description: "Sem Declaração"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 78,
                      type: "text",
                      label: "Religião",
                      validations: {
                        type: "string",
                        minLength: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              id: 14,
              title: "Filiação",
              icon: "how_to_reg",
              subgroups: [
                {
                  id: 30,
                  description:
                    "(Os dados dos pais do aluno devem ser informados no item “Adicionar Responsável” na tela inicial de matrícula para que eles possam ser indicados abaixo).",
                  fields: [
                    {
                      id: 79,
                      type: "select",
                      label: "Selecionar pai/mãe",
                      value: 1,
                      options: [
                        {
                          value: 1,
                          description: "Marcos José Barbosa"
                        },
                        {
                          value: 2,
                          description: "Amanda Lima"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 80,
                      type: "select",
                      label: "Selecionar pai/mãe",
                      value: 2,
                      options: [
                        {
                          value: 1,
                          description: "Marcos José Barbosa"
                        },
                        {
                          value: 2,
                          description: "Amanda Lima"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 15,
              title: "Documentos",
              icon: "description",
              subgroups: [
                {
                  id: 31,
                  fields: [
                    {
                      id: 81,
                      type: "text",
                      label: "RG/RNE",
                      value: "39023493X",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 82,
                      type: "text",
                      label: "Orgão Emissor",
                      value: "SSP",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 83,
                      type: "text",
                      label: "UF",
                      value: "SP",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 32,
                  fields: [
                    {
                      id: 84,
                      type: "text",
                      label: "CPF",
                      mask: "000.000.000-00",
                      value: "44724328894",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 33,
                  fields: [
                    {
                      id: 85,
                      type: "file",
                      label: "cópia do RG",
                      status: "APPROVED",
                      url:
                        "https://storage.googleapis.com/waybee-development/enrollments/images%20(1).jpeg"
                    },
                    {
                      id: 86,
                      type: "file",
                      label: "cópia do CPF",
                      status: "APPROVED",
                      url:
                        "https://storage.googleapis.com/waybee-development/enrollments/images%20(1).jpeg"
                    }
                  ]
                }
              ]
            },
            {
              id: 16,
              title: "Endereço e Contato",
              icon: "room",
              subgroups: [
                {
                  id: 34,
                  fields: [
                    {
                      id: 87,
                      type: "text",
                      label: "O(A) aluno(a) mora com quem",
                      value: "Pai",
                      validations: {
                        type: "string"
                      },
                      grid: {
                        xs: 12
                      }
                    },
                    {
                      id: 88,
                      type: "text",
                      label: "Endereço",
                      value: "Rua Augusta",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 89,
                      type: "number",
                      label: "Número",
                      value: 2516,
                      validations: {
                        type: "number"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 90,
                      type: "text",
                      label: "Complemento",
                      value: "Cj 142",
                      validations: {
                        type: "string"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 91,
                      type: "text",
                      label: "CEP",
                      value: "01412100",
                      mask: "00000-000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 92,
                      type: "text",
                      label: "Bairro",
                      value: "Cerqueira César",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 93,
                      type: "text",
                      label: "Cidade",
                      value: "São Paulo",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 94,
                      type: "text",
                      label: "Estado",
                      value: "SP",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 35,
                  fields: [
                    {
                      id: 95,
                      type: "text",
                      label: "Telefone 1",
                      mask: "(00)000000000",
                      value: "11965352887",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 96,
                      type: "text",
                      label: "Telefone 2",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 10,
          name: "Dados Escolares",
          icon: "school",
          status: "ERROR",
          msg: "Documento com falha de legibilidade",
          groups: [
            {
              id: 17,
              title: "Informações Gerais",
              icon: "settings",
              subgroups: [
                {
                  id: 36,
                  fields: [
                    {
                      id: 97,
                      type: "text",
                      label: "Nome da escola anterior",
                      value: "Colégio Bandeirantes",
                      validations: {
                        type: "string",
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 37,
                  fields: [
                    {
                      id: 98,
                      type: "select",
                      label: "País",
                      value: "BR",
                      options: [
                        {
                          value: "BR",
                          description: "Brasil"
                        },
                        {
                          value: "US",
                          description: "Estados Unidos"
                        },
                        {
                          value: "CA",
                          description: "Canadá"
                        },
                        {
                          value: "UK",
                          description: "Reino Unido"
                        }
                      ]
                    },
                    {
                      id: 99,
                      type: "select",
                      label: "Estado",
                      value: "SP",
                      options: [
                        {
                          value: "SP",
                          description: "São Paulo"
                        },
                        {
                          value: "RJ",
                          description: "Rio de Janeiro"
                        },
                        {
                          value: "ES",
                          description: "Espírito Santo"
                        },
                        {
                          value: "MG",
                          description: "Minas Gerais"
                        }
                      ]
                    },
                    {
                      id: 100,
                      type: "select",
                      label: "Cidade",
                      value: 1,
                      options: [
                        {
                          value: 1,
                          description: "São Paulo"
                        },
                        {
                          value: 2,
                          description: "Rio de Janeiro"
                        },
                        {
                          value: 3,
                          description: "Vitória"
                        },
                        {
                          value: 4,
                          description: "Belo Horizonte"
                        }
                      ]
                    }
                  ]
                },
                {
                  id: 38,
                  fields: [
                    {
                      id: 101,
                      type: "select",
                      label: "Curso",
                      value: 4,
                      options: [
                        {
                          value: 1,
                          description: "Educação Infantil"
                        },
                        {
                          value: 2,
                          description: "Ensino Fundamental I"
                        },
                        {
                          value: 3,
                          description: "Ensino Fundamental II"
                        },
                        {
                          value: 4,
                          description: "Ensino Médio"
                        }
                      ]
                    },
                    {
                      id: 102,
                      type: "select",
                      label: "Ultimo ano cursado",
                      value: 15,
                      options: [
                        {
                          value: 1,
                          description: "Berçário"
                        },
                        {
                          value: 2,
                          description: "N1"
                        },
                        {
                          value: 3,
                          description: "N2"
                        },
                        {
                          value: 4,
                          description: "N3"
                        },
                        {
                          value: 5,
                          description: "1º ano Fundamental"
                        },
                        {
                          value: 6,
                          description: "2º ano Fundamental"
                        },
                        {
                          value: 7,
                          description: "3º ano Fundamental"
                        },
                        {
                          value: 8,
                          description: "4º ano Fundamental"
                        },
                        {
                          value: 9,
                          description: "5º ano Fundamental"
                        },
                        {
                          value: 10,
                          description: "6º ano Fundamental"
                        },
                        {
                          value: 11,
                          description: "7º ano Fundamental"
                        },
                        {
                          value: 12,
                          description: "8º ano Fundamental"
                        },
                        {
                          value: 13,
                          description: "9º ano Fundamental"
                        },
                        {
                          value: 14,
                          description: "1º ano Médio"
                        },
                        {
                          value: 15,
                          description: "2º ano Médio"
                        },
                        {
                          value: 16,
                          description: "3º ano Médio"
                        }
                      ]
                    },
                    {
                      id: 103,
                      type: "select",
                      label: "Ano Letivo",
                      value: 2018,
                      options: [
                        {
                          value: 2010,
                          description: "2010"
                        },
                        {
                          value: 2011,
                          description: "2011"
                        },
                        {
                          value: 2012,
                          description: "2012"
                        },
                        {
                          value: 2013,
                          description: "2013"
                        },
                        {
                          value: 2014,
                          description: "2014"
                        },
                        {
                          value: 2015,
                          description: "2015"
                        },
                        {
                          value: 2016,
                          description: "2016"
                        },
                        {
                          value: 2017,
                          description: "2017"
                        },
                        {
                          value: 2018,
                          description: "2018"
                        },
                        {
                          value: 2019,
                          description: "2019"
                        }
                      ]
                    }
                  ]
                },
                {
                  id: 39,
                  fields: [
                    {
                      id: 104,
                      type: "file",
                      label: "histórico escolar",
                      status: "ERROR",
                      url:
                        "https://storage.googleapis.com/waybee-development/enrollments/Modelo-de-Historico-Escolar-3.jpg",
                      msg:
                        "Problema de legibilidade em imagem enviada - apontado por Leila Silva (12/08/2019 às 14:24)"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 11,
          name: "Informações Médicas",
          icon: "local_hospital",
          status: "PENDING",
          groups: [
            {
              id: 18,
              title: "Ficha Médica",
              icon: "local_hospital",
              subgroups: [
                {
                  id: 40,
                  fields: [
                    {
                      id: 105,
                      type: "number",
                      label: "Altura do aluno",
                      validations: {
                        type: "number",
                        notNull: true
                      }
                    },
                    {
                      id: 106,
                      type: "number",
                      label: "Peso",
                      validations: {
                        type: "number",
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 41,
                  fields: [
                    {
                      id: 107,
                      type: "radio",
                      label: "Tipo sanguíneo",
                      options: [
                        {
                          value: "O",
                          description: "O"
                        },
                        {
                          value: "A",
                          description: "A"
                        },
                        {
                          value: "B",
                          description: "B"
                        },
                        {
                          value: "AB",
                          description: "AB"
                        }
                      ]
                    },
                    {
                      id: 108,
                      type: "radio",
                      label: "Fator RH",
                      options: [
                        {
                          value: "+",
                          description: "+"
                        },
                        {
                          value: "-",
                          description: "-"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 19,
              title: "Alergias",
              icon: "local_hospital",
              addButton: "Adicionar Alergia",
              subgroups: [
                {
                  id: 42,
                  fields: [
                    {
                      id: 109,
                      type: "radio",
                      label: "Apresenta algum tipo de alergia?",
                      render: [
                        {
                          id: 16,
                          type: "subgroup",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    }
                  ]
                },
                {
                  id: 43,
                  conditional: true,
                  replicable: true,
                  fields: [
                    {
                      id: 110,
                      type: "select",
                      label: "Fator desencadeante",
                      options: [
                        {
                          value: "Pelos",
                          description: "Pelos"
                        },
                        {
                          value: "Pó",
                          description: "Pó"
                        }
                      ]
                    },
                    {
                      id: 111,
                      type: "text",
                      label: "Especificação"
                    },
                    {
                      id: 112,
                      type: "text",
                      label: "Procedimento"
                    }
                  ]
                }
              ]
            },
            {
              id: 20,
              title: "Informações sobre a saúde do(a) aluno(a)",
              icon: "local_hospital",
              subgroups: [
                {
                  id: 44,
                  fields: [
                    {
                      id: 113,
                      type: "radio",
                      label:
                        "Alguma doença grave já contraída / Doenças de infância / Internações?",
                      render: [
                        {
                          id: 43,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 114,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                },
                {
                  id: 45,
                  fields: [
                    {
                      id: 115,
                      type: "radio",
                      label: "Foi submetido(a) a alguma cirurgia?",
                      render: [
                        {
                          id: 45,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 116,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                },
                {
                  id: 46,
                  fields: [
                    {
                      id: 117,
                      type: "radio",
                      label:
                        "Esta fazendo algum tratamento especial, com duração superior a um mês?",
                      render: [
                        {
                          id: 47,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 118,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                },
                {
                  id: 47,
                  fields: [
                    {
                      id: 119,
                      type: "radio",
                      label: "Restrições à atividade física?",
                      render: [
                        {
                          id: 49,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 120,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                }
              ]
            },
            {
              id: 21,
              title: "Informações para eventual necessidade",
              icon: "local_hospital",
              addButton: "Adicionar Hospital",
              subgroups: [
                {
                  id: 48,
                  fields: [
                    {
                      id: 121,
                      type: "radio",
                      label: "Possui Plano de Saúde?",
                      render: [
                        {
                          id: 51,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        },
                        {
                          id: 22,
                          type: "subgroup",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 122,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                },
                {
                  id: 49,
                  conditional: true,
                  replicable: true,
                  fields: [
                    {
                      id: 123,
                      type: "text",
                      label: "Nome do Hospital"
                    },
                    {
                      id: 124,
                      type: "text",
                      label: "Endereço"
                    },
                    {
                      id: 125,
                      type: "text",
                      label: "Bairro"
                    },
                    {
                      id: 126,
                      type: "text",
                      label: "Telefone",
                      mask: "(00)000000000"
                    }
                  ]
                }
              ]
            },
            {
              id: 22,
              title:
                "Recomendações de medicações que o aluno(a) está acostumado(a) a usar, inclusive medicação homéopatica",
              icon: "local_hospital",
              addButton: "Adicionar Medicação",
              subgroups: [
                {
                  id: 50,
                  replicable: true,
                  fields: [
                    {
                      id: 127,
                      type: "text",
                      label: "Sintoma",
                      grid: {
                        xs: 12
                      }
                    },
                    {
                      id: 128,
                      type: "text",
                      label: "Nome da Medicação"
                    },
                    {
                      id: 129,
                      type: "text",
                      label: "Dosagem"
                    }
                  ]
                }
              ]
            },
            {
              id: 23,
              title:
                "Contatos de emergência, caso os responsáveis não sejam encontrados",
              icon: "contact_phone",
              addButton: "Adicionar Contato de emergência",
              subgroups: [
                {
                  id: 51,
                  replicable: true,
                  fields: [
                    {
                      id: 130,
                      type: "text",
                      label: "Nome",
                      validations: {
                        type: "string",
                        minLength: 5
                      }
                    },
                    {
                      id: 131,
                      type: "text",
                      label: "Telefone do contato",
                      mask: "(00)000000000"
                    },
                    {
                      id: 132,
                      type: "text",
                      label: "Parentesco",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 12,
          name: "Autorização de saída",
          icon: "supervised_user_circle",
          status: "PENDING",
          groups: [
            {
              id: 24,
              title: "Informações Importantes",
              icon: "info",
              subgroups: [
                {
                  id: 52,
                  description:
                    "O aluno não autorizado a sair deverá aguardar seus pais/responsáveis dentro do colégio, não sendo permitida a sua saída com outras pessoas (pais de amigos, parentes, conhecidos) sem estarem devidamente autorizados.\n Informamos que as identificações serão produzidas em três vias, para que sejam utilizadas na entrada e saída do(a) aluno(a) e/ou responsável do colégio.",
                  fields: []
                }
              ]
            },
            {
              id: 25,
              title: "Termo de Autorização de Saída",
              icon: "description",
              subgroups: [
                {
                  id: 53,
                  description: "O(a) aluno(a) está autorizado(a) a sair:",
                  fields: [
                    {
                      id: 133,
                      type: "radio",
                      options: [
                        {
                          value: "Vermelha",
                          description:
                            "Somente em companhia dos pais e/ou responsáveis - Carteirinha Vermelha"
                        },
                        {
                          value: "Verde",
                          description:
                            "Desacompanhado(a) (Portaria e/ou Estacionamento) - Carteirinha Verde"
                        }
                      ],
                      grid: {
                        sm: 12
                      }
                    }
                  ]
                }
              ]
            },
            {
              id: 26,
              title: "Responsáveis autorizados a retirar o(a) aluno(a)",
              icon: "supervised_user_circle",
              addButton: "Adicionar madrastra/padrasto",
              subgroups: [
                {
                  id: 54,
                  description:
                    "(O Pai e a Mãe legalmente sempre são autorizados, exceto por Decisão Judicial. Neste caso, a Secretaria deverá ter uma cópia da definição judicial.)",
                  fields: [
                    {
                      id: 134,
                      type: "select",
                      label: "Selecionar pai/mãe",
                      options: [
                        {
                          value: 1,
                          description: "Marcos José Barbosa"
                        },
                        {
                          value: 2,
                          description: "Amanda Lima"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 135,
                      type: "select",
                      label: "Selecionar pai/mãe",
                      options: [
                        {
                          value: 1,
                          description: "Marcos José Barbosa"
                        },
                        {
                          value: 2,
                          description: "Amanda Lima"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 27,
              title: "Transporte Escolar",
              icon: "directions_bus",
              subgroups: [
                {
                  id: 55,
                  description:
                    "(O Transporte Escolar não é de responsabilidade do Colégio Albert Sabin, pois é um serviço prestado por empresas independentes)",
                  fields: [
                    {
                      id: 136,
                      type: "radio",
                      label: "O(a) aluno(a) utiliza transporte escolar?",
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    }
                  ]
                },
                {
                  id: 56,
                  fields: [
                    {
                      id: 137,
                      type: "select",
                      label: "Selecione uma das opções de transporte",
                      render: [
                        {
                          id: 67,
                          type: "field",
                          renderIf: {
                            const: 2
                          }
                        }
                      ],
                      options: [
                        {
                          value: 1,
                          description: "Vã"
                        },
                        {
                          value: 2,
                          description: "Outros"
                        }
                      ]
                    },
                    {
                      id: 138,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                }
              ]
            },
            {
              id: 28,
              title: "Outros responsáveis com autorização de saída",
              icon: "supervised_user_circle",
              addButton: "Adicionar outro responsável",
              subgroups: [
                {
                  id: 57,
                  fields: [
                    {
                      id: 139,
                      type: "text",
                      label: "Nome"
                    },
                    {
                      id: 140,
                      type: "text",
                      label: "CPF",
                      mask: "000.000.000-00"
                    },
                    {
                      id: 141,
                      type: "text",
                      label: "Parentesco"
                    },
                    {
                      id: 142,
                      type: "text",
                      label: "Telefone",
                      mask: "(00)000000000"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: 4,
      name: "Alice Lima",
      type: "Aluno",
      status: "PENDING",
      forms: [
        {
          id: 9,
          name: "Informações Gerais",
          icon: "settings",
          status: "PENDING",
          groups: [
            {
              id: 29,
              title: "Informações Gerais",
              icon: "settings",
              subgroups: [
                {
                  id: 58,
                  fields: [
                    {
                      id: 143,
                      type: "text",
                      label: "Nome Completo",
                      value: "Alice Lima",
                      validations: {
                        type: "string",
                        minLength: 5,
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 59,
                  fields: [
                    {
                      id: 144,
                      type: "date",
                      label: "Data de Nascimento",
                      value: "04/06/2002",
                      validations: {
                        type: "string",
                        notNull: true
                      }
                    },
                    {
                      id: 145,
                      type: "select",
                      label: "País de nascimento",
                      options: [
                        {
                          value: "BR",
                          description: "Brasil"
                        },
                        {
                          value: "US",
                          description: "Estados Unidos"
                        },
                        {
                          value: "CA",
                          description: "Canadá"
                        },
                        {
                          value: "UK",
                          description: "Reino Unido"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 146,
                      type: "select",
                      label: "Estado",
                      options: [
                        {
                          value: "SP",
                          description: "São Paulo"
                        },
                        {
                          value: "RJ",
                          description: "Rio de Janeiro"
                        },
                        {
                          value: "ES",
                          description: "Espírito Santo"
                        },
                        {
                          value: "MG",
                          description: "Minas Gerais"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 147,
                      type: "select",
                      label: "Cidade",
                      options: [
                        {
                          value: 1,
                          description: "São Paulo"
                        },
                        {
                          value: 2,
                          description: "Rio de Janeiro"
                        },
                        {
                          value: 3,
                          description: "Vitória"
                        },
                        {
                          value: 4,
                          description: "Belo Horizonte"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 60,
                  fields: [
                    {
                      id: 148,
                      type: "radio",
                      label: "Sexo",
                      value: "F",
                      options: [
                        {
                          value: "F",
                          description: "Feminino"
                        },
                        {
                          value: "M",
                          description: "Masculino"
                        }
                      ],
                      grid: {
                        xs: 12
                      }
                    },
                    {
                      id: 149,
                      type: "select",
                      label: "Cor / Raça (p/ Censo Escolar IBGE)",
                      value: 1,
                      options: [
                        {
                          value: 1,
                          description: "Branco"
                        },
                        {
                          value: 2,
                          description: "Preto"
                        },
                        {
                          value: 3,
                          description: "Pardo"
                        },
                        {
                          value: 4,
                          description: "Amarelo"
                        },
                        {
                          value: 5,
                          description: "Indígena"
                        },
                        {
                          value: 6,
                          description: "Sem Declaração"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 150,
                      type: "text",
                      label: "Religião",
                      validations: {
                        type: "string",
                        minLength: 1
                      }
                    }
                  ]
                }
              ]
            },
            {
              id: 30,
              title: "Filiação",
              icon: "how_to_reg",
              subgroups: [
                {
                  id: 61,
                  description:
                    "(Os dados dos pais do aluno devem ser informados no item “Adicionar Responsável” na tela inicial de matrícula para que eles possam ser indicados abaixo).",
                  fields: [
                    {
                      id: 151,
                      type: "select",
                      label: "Selecionar pai/mãe",
                      options: [
                        {
                          value: 1,
                          description: "Marcos José Barbosa"
                        },
                        {
                          value: 2,
                          description: "Amanda Lima"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 152,
                      type: "select",
                      label: "Selecionar pai/mãe",
                      options: [
                        {
                          value: 1,
                          description: "Marcos José Barbosa"
                        },
                        {
                          value: 2,
                          description: "Amanda Lima"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 31,
              title: "Documentos",
              icon: "description",
              subgroups: [
                {
                  id: 62,
                  fields: [
                    {
                      id: 153,
                      type: "text",
                      label: "RG/RNE",
                      value: "890234934",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 154,
                      type: "text",
                      label: "Orgão Emissor",
                      value: "SSP",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 155,
                      type: "text",
                      label: "UF",
                      value: "SP",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 63,
                  fields: [
                    {
                      id: 156,
                      type: "text",
                      label: "CPF",
                      value: "65478698898",
                      mask: "000.000.000-00",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 64,
                  fields: [
                    {
                      id: 157,
                      type: "file",
                      label: "cópia do RG",
                      status: "NOT_SENT"
                    },
                    {
                      id: 158,
                      type: "file",
                      label: "cópia do CPF",
                      status: "NOT_SENT"
                    }
                  ]
                }
              ]
            },
            {
              id: 32,
              title: "Endereço e Contato",
              icon: "room",
              subgroups: [
                {
                  id: 65,
                  fields: [
                    {
                      id: 159,
                      type: "text",
                      label: "O(A) aluno(a) mora com quem",
                      validations: {
                        type: "string"
                      },
                      grid: {
                        xs: 12
                      }
                    },
                    {
                      id: 160,
                      type: "text",
                      label: "Endereço",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 161,
                      type: "number",
                      label: "Número",
                      validations: {
                        type: "number"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 162,
                      type: "text",
                      label: "Complemento",
                      validations: {
                        type: "string"
                      },
                      grid: {
                        xs: 6,
                        sm: 3
                      }
                    },
                    {
                      id: 163,
                      type: "text",
                      label: "CEP",
                      mask: "00000-000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 164,
                      type: "text",
                      label: "Bairro",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 165,
                      type: "text",
                      label: "Cidade",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 166,
                      type: "text",
                      label: "Estado",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                },
                {
                  id: 66,
                  fields: [
                    {
                      id: 167,
                      type: "text",
                      label: "Telefone 1",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    },
                    {
                      id: 168,
                      type: "text",
                      label: "Telefone 2",
                      mask: "(00)000000000",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 10,
          name: "Dados Escolares",
          icon: "school",
          status: "PENDING",
          groups: [
            {
              id: 33,
              title: "Informações Gerais",
              icon: "settings",
              subgroups: [
                {
                  id: 67,
                  fields: [
                    {
                      id: 169,
                      type: "text",
                      label: "Nome da escola anterior",
                      validations: {
                        type: "string",
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 68,
                  fields: [
                    {
                      id: 170,
                      type: "select",
                      label: "País",
                      options: [
                        {
                          value: "BR",
                          description: "Brasil"
                        },
                        {
                          value: "US",
                          description: "Estados Unidos"
                        },
                        {
                          value: "CA",
                          description: "Canadá"
                        },
                        {
                          value: "UK",
                          description: "Reino Unido"
                        }
                      ]
                    },
                    {
                      id: 171,
                      type: "select",
                      label: "Estado",
                      options: [
                        {
                          value: "SP",
                          description: "São Paulo"
                        },
                        {
                          value: "RJ",
                          description: "Rio de Janeiro"
                        },
                        {
                          value: "ES",
                          description: "Espírito Santo"
                        },
                        {
                          value: "MG",
                          description: "Minas Gerais"
                        }
                      ]
                    },
                    {
                      id: 172,
                      type: "select",
                      label: "Cidade",
                      options: [
                        {
                          value: 1,
                          description: "São Paulo"
                        },
                        {
                          value: 2,
                          description: "Rio de Janeiro"
                        },
                        {
                          value: 3,
                          description: "Vitória"
                        },
                        {
                          value: 4,
                          description: "Belo Horizonte"
                        }
                      ]
                    }
                  ]
                },
                {
                  id: 69,
                  fields: [
                    {
                      id: 173,
                      type: "select",
                      label: "Curso",
                      options: [
                        {
                          value: 1,
                          description: "Educação Infantil"
                        },
                        {
                          value: 2,
                          description: "Ensino Fundamental I"
                        },
                        {
                          value: 3,
                          description: "Ensino Fundamental II"
                        },
                        {
                          value: 4,
                          description: "Ensino Médio"
                        }
                      ]
                    },
                    {
                      id: 174,
                      type: "select",
                      label: "Ultimo ano cursado",
                      options: [
                        {
                          value: 1,
                          description: "Berçário"
                        },
                        {
                          value: 2,
                          description: "N1"
                        },
                        {
                          value: 3,
                          description: "N2"
                        },
                        {
                          value: 4,
                          description: "N3"
                        },
                        {
                          value: 5,
                          description: "1º ano Fundamental"
                        },
                        {
                          value: 6,
                          description: "2º ano Fundamental"
                        },
                        {
                          value: 7,
                          description: "3º ano Fundamental"
                        },
                        {
                          value: 8,
                          description: "4º ano Fundamental"
                        },
                        {
                          value: 9,
                          description: "5º ano Fundamental"
                        },
                        {
                          value: 10,
                          description: "6º ano Fundamental"
                        },
                        {
                          value: 11,
                          description: "7º ano Fundamental"
                        },
                        {
                          value: 12,
                          description: "8º ano Fundamental"
                        },
                        {
                          value: 13,
                          description: "9º ano Fundamental"
                        },
                        {
                          value: 14,
                          description: "1º ano Médio"
                        },
                        {
                          value: 15,
                          description: "2º ano Médio"
                        },
                        {
                          value: 16,
                          description: "3º ano Médio"
                        }
                      ]
                    },
                    {
                      id: 175,
                      type: "select",
                      label: "Ano Letivo",
                      options: [
                        {
                          value: 2010,
                          description: "2010"
                        },
                        {
                          value: 2011,
                          description: "2011"
                        },
                        {
                          value: 2012,
                          description: "2012"
                        },
                        {
                          value: 2013,
                          description: "2013"
                        },
                        {
                          value: 2014,
                          description: "2014"
                        },
                        {
                          value: 2015,
                          description: "2015"
                        },
                        {
                          value: 2016,
                          description: "2016"
                        },
                        {
                          value: 2017,
                          description: "2017"
                        },
                        {
                          value: 2018,
                          description: "2018"
                        },
                        {
                          value: 2019,
                          description: "2019"
                        }
                      ]
                    }
                  ]
                },
                {
                  id: 70,
                  fields: [
                    {
                      id: 176,
                      type: "file",
                      label: "histórico escolar",
                      status: "NOT_SENT"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 11,
          name: "Informações Médicas",
          icon: "local_hospital",
          status: "PENDING",
          groups: [
            {
              id: 34,
              title: "Ficha Médica",
              icon: "local_hospital",
              subgroups: [
                {
                  id: 71,
                  fields: [
                    {
                      id: 177,
                      type: "number",
                      label: "Altura do aluno",
                      validations: {
                        type: "number",
                        notNull: true
                      }
                    },
                    {
                      id: 178,
                      type: "number",
                      label: "Peso",
                      validations: {
                        type: "number",
                        notNull: true
                      }
                    }
                  ]
                },
                {
                  id: 72,
                  fields: [
                    {
                      id: 179,
                      type: "radio",
                      label: "Tipo sanguíneo",
                      options: [
                        {
                          value: "O",
                          description: "O"
                        },
                        {
                          value: "A",
                          description: "A"
                        },
                        {
                          value: "B",
                          description: "B"
                        },
                        {
                          value: "AB",
                          description: "AB"
                        }
                      ]
                    },
                    {
                      id: 180,
                      type: "radio",
                      label: "Fator RH",
                      options: [
                        {
                          value: "+",
                          description: "+"
                        },
                        {
                          value: "-",
                          description: "-"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 35,
              title: "Alergias",
              icon: "local_hospital",
              addButton: "Adicionar Alergia",
              subgroups: [
                {
                  id: 73,
                  fields: [
                    {
                      id: 181,
                      type: "radio",
                      label: "Apresenta algum tipo de alergia?",
                      render: [
                        {
                          id: 16,
                          type: "subgroup",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    }
                  ]
                },
                {
                  id: 74,
                  conditional: true,
                  replicable: true,
                  fields: [
                    {
                      id: 182,
                      type: "select",
                      label: "Fator desencadeante",
                      options: [
                        {
                          value: "Pelos",
                          description: "Pelos"
                        },
                        {
                          value: "Pó",
                          description: "Pó"
                        }
                      ]
                    },
                    {
                      id: 183,
                      type: "text",
                      label: "Especificação"
                    },
                    {
                      id: 184,
                      type: "text",
                      label: "Procedimento"
                    }
                  ]
                }
              ]
            },
            {
              id: 36,
              title: "Informações sobre a saúde do(a) aluno(a)",
              icon: "local_hospital",
              subgroups: [
                {
                  id: 75,
                  fields: [
                    {
                      id: 185,
                      type: "radio",
                      label:
                        "Alguma doença grave já contraída / Doenças de infância / Internações?",
                      render: [
                        {
                          id: 43,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 186,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                },
                {
                  id: 76,
                  fields: [
                    {
                      id: 187,
                      type: "radio",
                      label: "Foi submetido(a) a alguma cirurgia?",
                      render: [
                        {
                          id: 45,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 188,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                },
                {
                  id: 77,
                  fields: [
                    {
                      id: 189,
                      type: "radio",
                      label:
                        "Esta fazendo algum tratamento especial, com duração superior a um mês?",
                      render: [
                        {
                          id: 47,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 190,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                },
                {
                  id: 78,
                  fields: [
                    {
                      id: 191,
                      type: "radio",
                      label: "Restrições à atividade física?",
                      render: [
                        {
                          id: 49,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 192,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                }
              ]
            },
            {
              id: 37,
              title: "Informações para eventual necessidade",
              icon: "local_hospital",
              addButton: "Adicionar Hospital",
              subgroups: [
                {
                  id: 79,
                  fields: [
                    {
                      id: 193,
                      type: "radio",
                      label: "Possui Plano de Saúde?",
                      render: [
                        {
                          id: 51,
                          type: "field",
                          renderIf: {
                            const: "S"
                          }
                        },
                        {
                          id: 22,
                          type: "subgroup",
                          renderIf: {
                            const: "S"
                          }
                        }
                      ],
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    },
                    {
                      id: 194,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                },
                {
                  id: 80,
                  conditional: true,
                  replicable: true,
                  fields: [
                    {
                      id: 195,
                      type: "text",
                      label: "Nome do Hospital"
                    },
                    {
                      id: 196,
                      type: "text",
                      label: "Endereço"
                    },
                    {
                      id: 197,
                      type: "text",
                      label: "Bairro"
                    },
                    {
                      id: 198,
                      type: "text",
                      label: "Telefone",
                      mask: "(00)000000000"
                    }
                  ]
                }
              ]
            },
            {
              id: 38,
              title:
                "Recomendações de medicações que o aluno(a) está acostumado(a) a usar, inclusive medicação homéopatica",
              icon: "local_hospital",
              addButton: "Adicionar Medicação",
              subgroups: [
                {
                  id: 81,
                  replicable: true,
                  fields: [
                    {
                      id: 199,
                      type: "text",
                      label: "Sintoma",
                      grid: {
                        xs: 12
                      }
                    },
                    {
                      id: 200,
                      type: "text",
                      label: "Nome da Medicação"
                    },
                    {
                      id: 201,
                      type: "text",
                      label: "Dosagem"
                    }
                  ]
                }
              ]
            },
            {
              id: 39,
              title:
                "Contatos de emergência, caso os responsáveis não sejam encontrados",
              icon: "contact_phone",
              addButton: "Adicionar Contato de emergência",
              subgroups: [
                {
                  id: 82,
                  replicable: true,
                  fields: [
                    {
                      id: 202,
                      type: "text",
                      label: "Nome",
                      validations: {
                        type: "string",
                        minLength: 5
                      }
                    },
                    {
                      id: 203,
                      type: "text",
                      label: "Telefone do contato",
                      mask: "(00)000000000"
                    },
                    {
                      id: 204,
                      type: "text",
                      label: "Parentesco",
                      validations: {
                        type: "string"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 12,
          name: "Autorização de saída",
          icon: "supervised_user_circle",
          status: "PENDING",
          groups: [
            {
              id: 40,
              title: "Informações Importantes",
              icon: "info",
              subgroups: [
                {
                  id: 83,
                  description:
                    "O aluno não autorizado a sair deverá aguardar seus pais/responsáveis dentro do colégio, não sendo permitida a sua saída com outras pessoas (pais de amigos, parentes, conhecidos) sem estarem devidamente autorizados.\n Informamos que as identificações serão produzidas em três vias, para que sejam utilizadas na entrada e saída do(a) aluno(a) e/ou responsável do colégio.",
                  fields: []
                }
              ]
            },
            {
              id: 41,
              title: "Termo de Autorização de Saída",
              icon: "description",
              subgroups: [
                {
                  id: 84,
                  description: "O(a) aluno(a) está autorizado(a) a sair:",
                  fields: [
                    {
                      id: 205,
                      type: "radio",
                      options: [
                        {
                          value: "Vermelha",
                          description:
                            "Somente em companhia dos pais e/ou responsáveis - Carteirinha Vermelha"
                        },
                        {
                          value: "Verde",
                          description:
                            "Desacompanhado(a) (Portaria e/ou Estacionamento) - Carteirinha Verde"
                        }
                      ],
                      grid: {
                        sm: 12
                      }
                    }
                  ]
                }
              ]
            },
            {
              id: 42,
              title: "Responsáveis autorizados a retirar o(a) aluno(a)",
              icon: "supervised_user_circle",
              addButton: "Adicionar madrastra/padrasto",
              subgroups: [
                {
                  id: 85,
                  description:
                    "(O Pai e a Mãe legalmente sempre são autorizados, exceto por Decisão Judicial. Neste caso, a Secretaria deverá ter uma cópia da definição judicial.)",
                  fields: [
                    {
                      id: 206,
                      type: "select",
                      label: "Selecionar pai/mãe",
                      options: [
                        {
                          value: 1,
                          description: "Marcos José Barbosa"
                        },
                        {
                          value: 2,
                          description: "Amanda Lima"
                        }
                      ],
                      validations: {
                        notNull: true
                      }
                    },
                    {
                      id: 207,
                      type: "select",
                      label: "Selecionar pai/mãe",
                      options: [
                        {
                          value: 1,
                          description: "Marcos José Barbosa"
                        },
                        {
                          value: 2,
                          description: "Amanda Lima"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              id: 43,
              title: "Transporte Escolar",
              icon: "directions_bus",
              subgroups: [
                {
                  id: 86,
                  description:
                    "(O Transporte Escolar não é de responsabilidade do Colégio Albert Sabin, pois é um serviço prestado por empresas independentes)",
                  fields: [
                    {
                      id: 208,
                      type: "radio",
                      label: "O(a) aluno(a) utiliza transporte escolar?",
                      options: [
                        {
                          value: "S",
                          description: "Sim"
                        },
                        {
                          value: "N",
                          description: "Não"
                        }
                      ]
                    }
                  ]
                },
                {
                  id: 87,
                  fields: [
                    {
                      id: 209,
                      type: "select",
                      label: "Selecione uma das opções de transporte",
                      render: [
                        {
                          id: 67,
                          type: "field",
                          renderIf: {
                            const: 2
                          }
                        }
                      ],
                      options: [
                        {
                          value: 1,
                          description: "Vã"
                        },
                        {
                          value: 2,
                          description: "Outros"
                        }
                      ]
                    },
                    {
                      id: 210,
                      conditional: true,
                      type: "text",
                      label: "Qual?"
                    }
                  ]
                }
              ]
            },
            {
              id: 44,
              title: "Outros responsáveis com autorização de saída",
              icon: "supervised_user_circle",
              addButton: "Adicionar outro responsável",
              subgroups: [
                {
                  id: 88,
                  fields: [
                    {
                      id: 211,
                      type: "text",
                      label: "Nome"
                    },
                    {
                      id: 212,
                      type: "text",
                      label: "CPF",
                      mask: "000.000.000-00"
                    },
                    {
                      id: 213,
                      type: "text",
                      label: "Parentesco"
                    },
                    {
                      id: 214,
                      type: "text",
                      label: "Telefone",
                      mask: "(00)000000000"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
export default mock;
