// React
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, withStyles } from "@material-ui/core";

const styles = ({ overrides }) => ({
  RoundedAvatar: {
    minWidth: ({ size }) => size,
    maxWidth: ({ size }) => size,
    height: ({ size }) => size,
    backgroundColor: overrides.MuiAvatar
      ? overrides.MuiAvatar.rounded.backgroundColor
      : "#FFF",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%"
  },
  Icon: {
    color: "white"
  },
  Photo: {
    width: "100%",
    height: "100%",
    borderRadius: "50%"
  }
});

class RoundedAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: props.imageSrc
    };
  }

  handleErrorImg() {
    this.setState({ imageSrc: undefined });
  }

  render() {
    const { imageSrc } = this.state;
    const { classes, imageAlt, icon } = this.props;

    return (
      <span className={`RoundedAvatar-root ${classes.RoundedAvatar}`}>
        {imageSrc ? (
          <img
            className={`Photo-root ${classes.Photo}`}
            src={imageSrc}
            onError={this.handleErrorImg.bind(this)}
            alt={imageAlt}
          />
        ) : (
          <Icon className={classes.Icon}>{icon}</Icon>
        )}
      </span>
    );
  }
}

RoundedAvatar.defaultProps = {
  imageSrc: undefined,
  imageAlt: "Imagem de avatar arredondada",
  size: 44
};

RoundedAvatar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  icon: PropTypes.node.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  size: PropTypes.number // Used in styles
};

export default withStyles(styles, { withTheme: true })(RoundedAvatar);
