import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import styled from "styled-components";
import RoundedAvatar from "../../images/RoundedAvatar/RoundedAvatar";

const StyledOption = styled.span`
  display: flex;
  align-items: center;
  p {
    margin: 0 0 0 10px;
  }
`;

const SelectInput = ({ field, form: { touched, errors }, ...props }) => {
  const { fieldOptions: properties, value: fieldValue } = props;

  const handleChange = async e => {
    await props.onChange(e);
    props.onBlur();
  };

  const defineValue = value => {
    if (properties.multiple) {
      return value || [];
    }
    return value || "";
  };

  return (
    <FormControl
      style={{ minWidth: "100%" }}
      error={touched[properties.name] && Boolean(errors[properties.name])}
      component="div"
    >
      <InputLabel>{properties.label}</InputLabel>
      <Select
        multiple={properties.multiple}
        value={defineValue(fieldValue)}
        onChange={handleChange}
        name={field.name}
        input={<Input />}
      >
        {properties.options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            component="div"
            button
          >
            <StyledOption>
              {option.photo ? (
                <RoundedAvatar
                  key={option.value}
                  imageSrc={option.photo}
                  icon="person"
                  imageAlt="Foto de perfil do responsável"
                  size={24}
                />
              ) : null}
              <p>{option.description}</p>
            </StyledOption>
          </MenuItem>
        ))}
      </Select>
      {touched[properties.name] && Boolean(errors[properties.name]) && (
        <FormHelperText>{errors[properties.name]}</FormHelperText>
      )}
    </FormControl>
  );
};

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
SelectInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default SelectInput;
