import React from "react";
import PropTypes from "prop-types";
import { Dialog, withStyles } from "@material-ui/core";
import Button from "../../button/Button/Button";

const styles = {
  Paper: {
    overflowY: "visible",
    margin: 15,
    borderRadius: 8
  },
  DialogBody: {
    margin: "20px 12px"
  },
  DialogTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 300,
    padding: "0 26px",
    textAlign: "center"
  },
  DialogDescription: {
    margin: "20px 0 30px 0",
    textAlign: "center",
    fontWeight: 400,
    fontSize: 14
  }
};

const InfoDialog = ({ classes, open, handleClose, title, description }) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.Paper }}
      maxWidth="sm"
    >
      <div className={`DialogBody-root ${classes.DialogBody}`}>
        <h1 className={`DialogTitle-root ${classes.DialogTitle}`}>{title}</h1>
        <p className={`DialogDescription-root ${classes.DialogDescription}`}>
          {description}
        </p>
        <Button
          href="#"
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleClose}
        >
          Ok
        </Button>
      </div>
    </Dialog>
  );
};

InfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired // https://material-ui.com/pt/styles/advanced/#withstyles
};

export default withStyles(styles, { withTheme: true })(InfoDialog);
