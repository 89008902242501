import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import FormLabel from "../../form/FormLabel/FormLabel";

const CheckInput = ({ field, form, form: { touched, errors }, ...props }) => {
  const { fieldOptions: properties, value: fieldValue } = props;
  const checkedValues = {};
  const values = [];

  const handleChange = e => {
    checkedValues[e.target.value] = e.target.checked;
    if (e.target.checked) {
      values.push(e.target.value);
    } else {
      values.splice(values.indexOf(e.target.value), 1);
    }

    const targetWrapper = document.createElement("wrapper");
    targetWrapper.value = values.length > 0 ? values : null;
    targetWrapper.name = field.name;
    console.log(targetWrapper.value);
    console.log(field.name);
    targetWrapper.addEventListener("change", async event => {
      props.onChange(event);
      props.onBlur();
    });

    const event = new Event("change");
    targetWrapper.dispatchEvent(event);
  };

  if (fieldValue) {
    fieldValue.forEach(value => {
      checkedValues[value] = true;
      if (!values.includes(value)) {
        values.push(value);
      }
    });
  }

  return (
    <React.Fragment>
      <FormLabel text={properties.label} />
      <Grid container spacing={3} component="div">
        {properties.options.map(option => (
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            component="div"
            key={option.value}
          >
            <FormControlLabel
              style={{ color: "rgba(0,0,0,.5)" }}
              control={(
                <Checkbox
                  name={`${field.name}-${option.value}`}
                  checked={checkedValues[option.value] || false}
                  onChange={handleChange}
                  value={option.value}
                  color="primary"
                />
)}
              label={option.description}
            />
          </Grid>
        ))}
      </Grid>

      {touched[properties.name] && Boolean(errors[properties.name]) && (
        <FormHelperText className="Mui-error">
          {errors[properties.name]}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
CheckInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.array
};

CheckInput.defaultProps = {
  value: []
};

export default CheckInput;
