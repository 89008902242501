// TODO Reducer de teste, remover ou ajustar após fase de implementação do projeto
import { SAVE_SCHOOL_DATA } from "../actions/actionTypes";

export const schoolDataReducer = (state = {}, action) => {
  if (action.type === SAVE_SCHOOL_DATA) {
    return {
      ...state,
      ...action.value
    };
  }
  return state;
};
