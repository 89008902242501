import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles, Collapse } from "@material-ui/core";
import { ArrowRight, ArrowDropDown } from "@material-ui/icons";
import { connect } from "react-redux";
import compose from "recompose/compose";
import InfoListHeader from "../../list/InfoListHeader/InfoListHeader";
import FormSubgroup from "../FormSubgroup/FormSubgroup";
import InfoListFooter from "../../list/InfoListFooter/InfoListFooter";

const styles = theme => ({
  FormGroup: {
    backgroundColor: "white",
    marginTop: 8
  },
  Header: {
    position: "relative"
  },
  CollapseIcon: {
    position: "absolute",
    top: 0,
    right: 6,
    height: "100%",
    width: 24,
    color: theme.palette.primary.dark,
    display: "flex",
    alignItems: "center"
  }
});

const FormGroup = ({
  classes,
  title,
  icon,
  collapsible,
  subgroups,
  actionButton,
  onAddSubgroup,
  onRemoveSubgroup,
  renderInfo
}) => {
  const [open, setOpen] = useState(false);

  const replicableSubgroups = subgroups.filter(subgroup => subgroup.replicable)
    .length;
  console.log(title);
  console.log(replicableSubgroups);
  console.log(subgroups);

  const renderArrow = () => {
    if (collapsible) {
      return open ? <ArrowDropDown /> : <ArrowRight />;
    }
    return null;
  };

  return (
    <div className={`FormGroup-root ${classes.FormGroup}`}>
      <div
        onClick={() => {
          setOpen(!open);
        }}
        role="presentation"
        className={`Header-root ${classes.Header}`}
      >
        {title && <InfoListHeader title={title} icon={icon} />}
        <span className={`CollapseIcon-root ${classes.CollapseIcon}`}>
          {renderArrow()}
        </span>
      </div>

      <Collapse in={!collapsible || open}>
        {subgroups.map(subgroup =>
          !(subgroup.conditional && !renderInfo[`subgroup${subgroup.id}`]) ? (
            <FormSubgroup
              key={subgroup.id}
              descriptionAcceptDangerousHTML={
                subgroup.descriptionAcceptDangerousHTML
              }
              description={subgroup.description}
              divider={subgroup.divider}
              fields={subgroup.fields}
              onRemove={
                subgroup.replicable && replicableSubgroups > 1
                  ? () => onRemoveSubgroup(subgroup.id)
                  : null
              }
            />
          ) : (
            <span />
          )
        )}

        {actionButton && (
          <InfoListFooter
            btnText={actionButton.text.toUpperCase()}
            btnIcon={actionButton.icon}
            btnAction={actionButton.action}
            btnLink={actionButton.link}
            onClickAddSubgroup={onAddSubgroup}
          />
        )}
      </Collapse>
    </div>
  );
};

FormGroup.defaultProps = {
  icon: undefined,
  collapsible: false,
  subgroups: [],
  actionButton: undefined,
  onAddSubgroup: () => {},
  onRemoveSubgroup: () => {}
};

FormGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  collapsible: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  subgroups: PropTypes.array, // http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
  actionButton: PropTypes.string,
  onAddSubgroup: PropTypes.func,
  onRemoveSubgroup: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  renderInfo: PropTypes.object.isRequired
};

const mapStateToProps = store => ({
  renderInfo: store.renderInfoState
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(FormGroup);
