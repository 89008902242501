import React from "react";
import { Icon, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = ({ typography }) => ({
  Header: {
    padding: "22px 36px 22px 20px",
    display: "flex",
    alignItems: "start"
  },
  Icon: {
    fontSize: 24,
    width: 24,
    height: 24,
    color: typography.h2.color
  },
  Text: {
    color: typography.h2.color,
    fontWeight: 500,
    fontSize: 18,
    margin: "0 0 0 18px",
    display: "inline-block"
  }
});

const InfoListHeader = ({ classes, icon, title }) => {
  return (
    <div className={`Header-root ${classes.Header}`}>
      {icon && <Icon className={classes.Icon}>{icon}</Icon>}
      <p className={`Text-root ${classes.Text}`}>{title}</p>
    </div>
  );
};

InfoListHeader.defaultProps = {
  icon: null
};

InfoListHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  icon: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(InfoListHeader);
