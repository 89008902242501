import React from "react";
import PropTypes from "prop-types";
import { withStyles, IconButton } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { Formik } from "formik";
import DOMPurify from "dompurify";
import FormFields from "../FormFields/FormFields";

const styles = () => ({
  DescriptionContainer: {
    minHeight: 60,
    display: "flex",
    alignItems: "center"
  },
  Description: {
    margin: 0,
    fontSize: "14px",
    color: "rgba(0,0,0,.5)",
    textAlign: "justify",
    lineHeight: "18px"
  },
  FormSubgroup: {
    padding: "14px 20px 14px 20px",
    borderBottom: ({ divider }) => (divider ? "1px solid #f2f2f2;" : 0)
  },
  DeleteButton: {
    display: "flex",
    justifyContent: "flex-end"
  }
});

const FormSubgroup = ({
  classes,
  descriptionAcceptDangerousHTML,
  description,
  onRemove,
  fields
}) => {
  const mapInitialValues = () => {
    const initialValues = {};
    fields.forEach(field => {
      initialValues[`field${field.id}`] = field.value;
    });
    return initialValues;
  };

  return (
    <div className={`FormSubgroup-root ${classes.FormSubgroup}`}>
      {description && (
        <div
          className={`DescriptionContainer-root ${classes.DescriptionContainer}`}
        >
          {descriptionAcceptDangerousHTML ? (
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description)
              }}
            />
          ) : (
            <p className={`Description-root ${classes.Description}`}>
              {description}
            </p>
          )}
        </div>
      )}
      {onRemove && (
        <div className={classes.DeleteButton}>
          <IconButton onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      {fields && fields.length > 0 && (
        <Formik
          onSubmit={() => {}}
          initialValues={mapInitialValues()}
          validateOnBlur
          render={props => <FormFields {...props} fields={fields} />}
        />
      )}
    </div>
  );
};

FormSubgroup.defaultProps = {
  description: "",
  fields: [],
  divider: true,
  descriptionAcceptDangerousHTML: false,
  onRemove: null
};

FormSubgroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  descriptionAcceptDangerousHTML: PropTypes.bool,
  description: PropTypes.string,
  divider: PropTypes.bool,
  onRemove: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array // http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
};

export default withStyles(styles, { withTheme: true })(FormSubgroup);
