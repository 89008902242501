import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import Moment from "@date-io/moment";

let localError = "";
const TimeInput = ({ field, form: { touched, errors }, ...props }) => {
  const { fieldOptions: properties, onChange, onBlur, value } = props;
  const initialTime = value
    ? new Moment().moment(value, "HH:mm").toDate()
    : null;
  const [selectedTime, handleTimeChange] = useState(initialTime);
  let fieldValue = value || "";

  const validateValue = timeValue => {
    if (!value) {
      localError = "";
      return;
    }
    const momentValue = new Moment().moment(timeValue, "HH:mm");

    if (value.includes("_") || !momentValue.isValid()) {
      localError = "Data inválida";
      return;
    }
    localError = "";
  };

  validateValue(fieldValue);

  const handleChange = (date, timeValue) => {
    const targetWrapper = document.createElement("wrapper");
    targetWrapper.value = timeValue;
    fieldValue = timeValue;
    targetWrapper.name = field.name;
    targetWrapper.addEventListener("change", async e => {
      onChange(e);
    });
    const event = new Event("change");
    targetWrapper.dispatchEvent(event);

    handleTimeChange(date);
  };

  const checkOnBlur = () => {
    if (
      !fieldValue ||
      fieldValue.includes("_") ||
      (touched[properties.name] && Boolean(errors[properties.name]))
    )
      return;

    onBlur();
  };

  const getErrorMessage = () => {
    if (localError) return localError;

    if (touched[properties.name] && errors[properties.name])
      return errors[properties.name];
    return "";
  };

  return (
    <MuiPickersUtilsProvider utils={Moment} locale="pt-br">
      <KeyboardTimePicker
        label={properties.label}
        ampm={false}
        fullWidth
        mask="__:__"
        value={selectedTime}
        onChange={handleChange}
        onClose={checkOnBlur}
        onBlur={checkOnBlur}
        helperText={getErrorMessage()}
        error={
          (touched[properties.name] && Boolean(errors[properties.name])) ||
          !!localError
        }
        invalidDateMessage="Formato inválido"
      />
    </MuiPickersUtilsProvider>
  );
};

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
TimeInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

TimeInput.defaultProps = {
  value: ""
};

export default TimeInput;
