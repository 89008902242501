import React from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  CustomSwal: {
    whiteSpace: "pre-wrap"
  }
});

const Alert = ({ open, type, title, msg, status, onClose, classes }) => {
  if (!open) return null;

  if (type === "swal") {
    Swal.fire({
      title,
      text: msg,
      customClass: classes.CustomSwal,
      icon: status,
      onClose
    });
    return null;
  }

  return <div />;
};

Alert.defaultProps = {
  onClose: () => {}
};

Alert.propTypes = {
  open: PropTypes.bool.isRequired,
  // swal
  type: PropTypes.oneOf(["swal"]).isRequired,
  title: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired // https://material-ui.com/pt/styles/advanced/#withstyles
};

export default withStyles(styles)(Alert);
