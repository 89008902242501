import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import Moment from "@date-io/moment";
import "moment/locale/pt-br";
import Ajv from "ajv";
import localize from "ajv-i18n/localize/pt-BR";

const ajv = new Ajv({ allErrors: true });
require("ajv-keywords")(ajv);
require("ajv-errors")(ajv);

let localError = "";
let formatedValue = "";
const DateInput = ({ field, form: { touched, errors }, ...props }) => {
  const { fieldOptions: properties, onChange, onBlur, value } = props;
  const dateFormat =
    properties.type === "datetime" ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
  const viewFormat =
    properties.type === "datetime" ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";
  const initialDate = value
    ? new Moment().moment(value, dateFormat).toDate()
    : null;
  const [selectedValue, handleDateChange] = useState(initialDate);
  let fieldValue = value || "";

  const validateValue = dateValue => {
    if (!dateValue) {
      localError = "";
      return;
    }

    const momentValue = new Moment().moment(dateValue, dateFormat);
    if (formatedValue.includes("_") || !momentValue.isValid()) {
      localError = "Data inválida";
      return;
    }

    const schema = {
      type: "string",
      format: "date",
      formatMaximum: properties.maxDate,
      formatMinimum: properties.minDate,
      errorMessage: {
        format: "Data inválida",
        formatMaximum: `Data máxima ${new Moment()
          .moment(properties.maxDate)
          .format(viewFormat)}`,
        formatMinimum: `Data mínima ${new Moment()
          .moment(properties.minDate)
          .format(viewFormat)}`
      }
    };

    const validate = ajv.compile(schema);
    const valueForValidate = momentValue.format(dateFormat);
    const valid = validate(valueForValidate);
    let msg = "";
    if (!valid) {
      localize(validate.errors);
      validate.errors.forEach(e => {
        msg += `${e.message}\n`;
      });
    }
    localError = msg;
  };

  validateValue(fieldValue);

  const handleChange = (date, dateValue) => {
    localError = "";
    formatedValue = dateValue;
    const targetWrapper = document.createElement("wrapper");
    fieldValue = new Moment().moment(dateValue, viewFormat).format(dateFormat);
    targetWrapper.value = fieldValue;
    targetWrapper.name = field.name;
    targetWrapper.addEventListener("change", async event => {
      onChange(event);
    });
    const event = new Event("change");
    targetWrapper.dispatchEvent(event);

    handleDateChange(date);
  };

  const checkOnBlur = () => {
    if (
      localError ||
      !fieldValue ||
      (touched[properties.name] && Boolean(errors[properties.name]))
    )
      return;

    onBlur();
  };

  const getErrorMessage = () => {
    if (localError) return localError;

    if (touched[properties.name] && errors[properties.name])
      return errors[properties.name];
    return "";
  };

  const Picker =
    properties.type === "datetime"
      ? KeyboardDateTimePicker
      : KeyboardDatePicker;
  return (
    <MuiPickersUtilsProvider utils={Moment} locale="pt-br">
      <Picker
        label={properties.label}
        value={selectedValue}
        onChange={handleChange}
        onClose={checkOnBlur}
        onBlur={checkOnBlur}
        ampm={properties.type === "datetime" ? false : null}
        fullWidth
        minDate={new Moment().moment(properties.minDate || "1900-01-01")}
        maxDate={new Moment().moment(properties.maxDate || "2100-01-01")}
        helperText={getErrorMessage()}
        error={
          (touched[properties.name] && Boolean(errors[properties.name])) ||
          !!localError
        }
        format={viewFormat}
        cancelLabel="Cancelar"
      />
    </MuiPickersUtilsProvider>
  );
};

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
DateInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default DateInput;
