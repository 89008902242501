import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import FormField from "../FormField/FormField";
import SimpleList from "../../list/SimpleList/SimpleList";
import Link from "../../link/Link";

const FormFields = props => {
  const {
    values,
    handleChange,
    setFieldTouched,
    setFieldError,
    setFieldValue,
    fields,
    touched
  } = props;

  return (
    <form>
      <Grid container spacing={3} component="div">
        {fields.map(field => {
          if (field.type === "list")
            return <SimpleList key={field.id} list={field.list} />;
          if (field.type === "link")
            return (
              <Link
                href={field.href}
                label={field.label}
                variant={field.variant}
                color={field.color}
                icon={field.icon}
              />
            );

          return (
            <FormField
              field={field}
              key={field.id}
              handleChange={handleChange}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              value={values[`field${field.id}`]}
              touched={touched[`field${field.id}`]}
            />
          );
        })}
      </Grid>
    </form>
  );
};

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
FormFields.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  touched: PropTypes.object.isRequired
};

export default FormFields;
