import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { withStyles, Container, Button } from "@material-ui/core";
import { connect } from "react-redux";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import PageTitle from "../../components/page/PageTitle/PageTitle";
import NavigationBack from "../../components/page/NavigationBack/NavigationBack";
import FormGroup from "../../components/form/FormGroup/FormGroup";
import PageHeader from "../../components/page/PageHeader/PageHeader";
import Service from "./FormGroupService";
import AppService from "../../AppService";
import { saveJsonForm } from "../../redux/actions";
import Alert from "../../components/alert/Alert/Alert";

const styles = theme => ({
  FormGroupPage: {
    [theme.breakpoints.up("sm")]: {
      margin: 20,
      marginBottom: 0
    }
  },
  Container: {
    padding: 0
  },
  SaveButtonContainer: {
    textAlign: "center",
    padding: `70px 24px ${
      theme.footer && theme.footer.spacing ? theme.footer.spacing : 130
    }px 24px`
  },
  SaveButton: {
    textTransform: "none",
    maxWidth: "100%",
    width: 400,
    height: 44,
    fontSize: 16,
    color: "white"
  }
});

class FormGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInfos: {},
      alertOptions: {},
      redirectToHome: false
    };
  }

  componentDidMount() {
    this.setState({ forms: this.findForm() });
  }

  findForm = () => {
    const { jsonForm, match } = this.props;
    const { idGroup, idForm } = match.params;

    let findedForm = "";
    if (jsonForm.formStudent) {
      findedForm = jsonForm.formStudent.find(
        form => form.id.toString() === idGroup
      );
    }

    if (!findedForm) {
      findedForm = jsonForm.formResponsible.find(
        form => form.id.toString() === idGroup
      );
    }

    if (findedForm) {
      this.setState({ formInfos: findedForm });
      if (findedForm.forms) {
        return findedForm.forms.find(form => form.id.toString() === idForm);
      }
    }

    return undefined;
  };

  onAddSubgroup = async group => {
    const { saveJsonForm: dispatchJsonForm, jsonForm } = this.props;
    try {
      const res = await Service.addSubgroup(group.id);
      const newSubgroups = res.data.map(subgroup => {
        const newSubgroup = subgroup;
        newSubgroup.replicable = true;
        return newSubgroup;
      });
      group.subgroups = group.subgroups.concat(newSubgroups); // eslint-disable-line no-param-reassign
      dispatchJsonForm(jsonForm);
    } catch (e) {
      AppService.notifyError(e);
      console.error(e);
      this.openAlert(
        "error",
        "Erro",
        "Ocorreu um erro ao adicionar formulário."
      );
    }
  };

  onRemoveSubgroup = async (group, subgroupId) => {
    const { saveJsonForm: dispatchJsonForm, jsonForm } = this.props;
    try {
      await Service.removeSubgroup(subgroupId);
      _.remove(group.subgroups, subgroup => subgroup.id === subgroupId); // eslint-disable-line no-param-reassign
      dispatchJsonForm(jsonForm);
    } catch (e) {
      AppService.notifyError(e);
      console.error(e);
      this.openAlert(
        "error",
        "Erro",
        "Ocorreu um erro ao adicionar formulário."
      );
    }
  };

  openAlert = (status, title, msg) => {
    this.setState({
      alertOptions: {
        open: true,
        status,
        title,
        msg
      }
    });
  };

  onCloseAlert = () => {
    this.setState({ alertOptions: { open: false } });
  };

  render() {
    const { classes, match, schoolData } = this.props;
    const { formInfos, forms, alertOptions, redirectToHome } = this.state;
    const { idGroup } = match.params;

    if (redirectToHome) return <Redirect to={`/status/${idGroup}`} />;
    if (!forms) return null;

    return (
      <Fragment>
        <PageHeader logo={schoolData.logo} />
        <PageTitle title={formInfos.name} hasPhoto photo={formInfos.photo} />
        <NavigationBack backTo={`/status/${idGroup}`} />
        <Container maxWidth="lg" className={classes.Container}>
          <div className={`FormGroupPage-root ${classes.FormGroupPage}`}>
            {forms.groups.map(group => (
              <FormGroup
                key={group.id}
                {...group}
                onAddSubgroup={() => this.onAddSubgroup(group)}
                onRemoveSubgroup={subgroupId =>
                  this.onRemoveSubgroup(group, subgroupId)
                }
              />
            ))}

            <div className={classes.SaveButtonContainer}>
              {forms.saveButton && (
                <Button
                  href=""
                  variant="contained"
                  color={forms.saveButton.color}
                  className={classes.SaveButton}
                  onClick={() =>
                    this.openAlert(
                      "success",
                      "Sucesso",
                      "Dados salvos com sucesso!"
                    )
                  }
                >
                  {forms.saveButton.text}
                </Button>
              )}
            </div>
          </div>
        </Container>

        <Alert
          open={alertOptions.open}
          type="swal"
          title={alertOptions.title}
          msg={alertOptions.msg}
          status={alertOptions.status}
          onClose={this.onCloseAlert}
        />
      </Fragment>
    );
  }
}

FormGroupPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  // eslint-disable-next-line react/forbid-prop-types
  jsonForm: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // https://reacttraining.com/react-router/web/example/url-params
  saveJsonForm: PropTypes.func.isRequired
};

const mapStateToProps = store => ({
  schoolData: store.schoolData,
  jsonForm: store.jsonForm
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveJsonForm }, dispatch);

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FormGroupPage);
