import { api } from "../../utils/api";

const getClickSignKey = studentId => {
  const enrollmentId = localStorage.getItem("enrollmentId");
  return api.post("Enrollments/getDocument", { studentId, enrollmentId });
};

const FormGroupService = {
  getClickSignKey
};

export default FormGroupService;
