import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import bugsnagReact from "@bugsnag/plugin-react";
import { Store } from "./redux/store";
import App from "./App";
import Service from "./AppService";

Service.initializeBugsnag();
const bugsnagClient = window.plugins.bugsnag;
bugsnagClient.use(bugsnagReact, React);

const ErrorBoundary = bugsnagClient.getPlugin("react");

Service.initializeHotjar();

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={Store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
