import { api } from "../../utils/api";
import AppService from "../../AppService";

const isMockActive = AppService.isMockActive();

const getUserData = data => {
  if (isMockActive) {
    return new Promise(resolve =>
      resolve({ data: { email: "d*****@waybee.com.br" } })
    );
  }
  return api.post("Enrollments/auth", data);
};

const AccessPageService = {
  getUserData
};

export default AccessPageService;
