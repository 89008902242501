import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import RoundedAvatar from "../../images/RoundedAvatar/RoundedAvatar";

const styles = theme => ({
  PageTitle: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "20px 26px"
  },
  TitleText: {
    margin: 0,
    color: theme.palette.secondary.dark,
    fontWeight: 300,
    fontSize: 24,
    lineHeight: "32px",
    marginLeft: "12px"
  }
});

const PageTitle = ({ classes, title, hasPhoto, photo }) => {
  return (
    <div className={`PageTitle-root ${classes.PageTitle}`}>
      {hasPhoto && (
        <RoundedAvatar
          icon="person"
          imageSrc={photo}
          imageAlt="Foto do tótulo da página"
        />
      )}
      <p className={`TitleText-root ${classes.TitleText}`}>{title}</p>
    </div>
  );
};

PageTitle.defaultProps = {
  hasPhoto: false,
  photo: undefined
};

PageTitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  title: PropTypes.string.isRequired,
  hasPhoto: PropTypes.bool,
  photo: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(PageTitle);
