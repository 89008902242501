import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  Link: {
    textDecoration: "none",
    display: "inline-block",
    padding: "25px 20px 20px 20px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px 20px 10px 20px"
    },
    "&:hover": {
      cursor: "pointer"
    }
  },
  Container: {
    display: "flex",
    alignItems: "center"
  },
  BackText: {
    display: "inline-block",
    color: "rgba(0,0,0,.5)",
    margin: "0 0 0 12px",
    fontWeight: 500,
    fontSize: 16
  },
  BackIcon: {
    color: "rgba(0,0,0,.5)",
    width: 20,
    height: 20
  }
});

const NavigationBack = ({ classes, backTo }) => {
  return (
    <Link to={backTo} className={classes.Link}>
      <div className={classes.Container}>
        <ArrowBack className={classes.BackIcon} />
        <p className={classes.BackText}>Voltar</p>
      </div>
    </Link>
  );
};

NavigationBack.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles,
  backTo: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(NavigationBack);
