import bugsnag from "@bugsnag/js";
import { hotjar } from "react-hotjar";
import { api } from "./utils/api";
import mockSabin from "./files/mock/sabin";
import mockCal from "./files/mock/cal";
import DefaultMuiTheme from "./utils/DefaultMuiTheme";

const {
  REACT_APP_ENV,
  REACT_APP_VERSION,
  REACT_APP_MOCK_ACTIVE,
  REACT_APP_MOCK_CLIENT_CODE
} = process.env;

function isMockActive() {
  return REACT_APP_MOCK_ACTIVE && REACT_APP_MOCK_ACTIVE === "true";
}

function getMockClientCode() {
  return (
    (isMockActive() &&
      REACT_APP_MOCK_CLIENT_CODE &&
      parseInt(REACT_APP_MOCK_CLIENT_CODE, 10)) ||
    0
  );
}

// 0 - Disable
// 1 - Augusto Laranja
// 2 - Albert Sabin
const mockClientCode = getMockClientCode();

const getStatusProperties = (status, theme, msg = "Formulário Incompleto") => {
  switch (status) {
    case "PENDING":
      return {
        icon: "edit",
        iconColor: theme.palette.secondary.main,
        iconText: "Continuar",
        msg: "Preenchimento em andamento",
        highlight: true
      };
    case "SIGNED":
    case "COMPLETED":
      return {
        icon: "check_circle_outline",
        iconColor: theme.palette.primary.main,
        iconText: "Completo",
        msg: "Dados Aprovados",
        highlight: false
      };
    case "ERROR":
      return {
        icon: "error_outline",
        iconColor: theme.palette.error.main,
        iconText: "Corrigir",
        msg,
        highlight: true
      };
    default:
      return {};
  }
};

const getJsonForm = () => {
  let mock = {};

  if (mockClientCode === 1) {
    mock = mockCal;
  }
  if (mockClientCode === 2) {
    mock = mockSabin;
  }

  if (mockClientCode !== 0) {
    return new Promise(resolve => resolve({ data: mock }));
  }
  return api.get("Enrollments");
};

const getSchoolData = () => {
  let url = "";
  if (mockClientCode === 1) {
    url = "https://waybee.s3.amazonaws.com/dev/cal/logo-cal.png";
  }
  if (mockClientCode === 2) {
    url = "https://waybee.s3.amazonaws.com/dev/sabin/logo-sabin.png";
  }

  if (mockClientCode !== 0) {
    return new Promise(resolve =>
      resolve({
        data: {
          logo: url,
          theme: DefaultMuiTheme
        }
      })
    );
  }
  return api.get("Enrollments/getSchoolData").then(response => {
    if (!response.data.theme) {
      response.data.theme = DefaultMuiTheme;
    }
    return response;
  });
};

const saveField = field => {
  if (mockClientCode !== 0) return null;

  const fieldData = {
    id: Object.keys(field)[0].split("field")[1],
    value: field[Object.keys(field)[0]]
  };

  return api.post("Enrollments/field", fieldData);
};

const callbackBeforeSend = report => {
  if (REACT_APP_ENV === "develop") {
    report.ignore();
  } else {
    const localStorageData = {
      enrollmentId: localStorage.getItem("enrollmentId")
    };
    report.updateMetaData("localStorage", localStorageData);
  }
};

const initializeHotjar = () => {
  if (REACT_APP_ENV !== "develop") {
    hotjar.initialize(1558366, 6);
  }
};

const initializeBugsnag = () => {
  window.plugins = {
    bugsnag: bugsnag({
      releaseStage: REACT_APP_ENV,
      apiKey: "be998e3cca7d8a7a2ca695e760b3ecb0",
      appVersion: REACT_APP_VERSION,
      beforeSend: callbackBeforeSend
    })
  };
};

const notifyError = (error, additionalInfo) => {
  const bugsnagClient = window.plugins.bugsnag;
  bugsnagClient.metaData = { additionalInfo };
  const errorObject = error instanceof Error ? error : new Error(error);
  bugsnagClient.notify(errorObject, {
    beforeSend: callbackBeforeSend
  });
};

/**
 * Resolves enrollment app name by the url path
 * @param url
 * @returns {string}
 */
function resolveAppName(url) {
  if (!url) return "matrículas";
  const parsedUrl = new URL(url.includes("http") ? "" : `http://${url}`);
  return parsedUrl.hostname.split(".")[0];
}

const AppService = {
  getStatusProperties,
  getJsonForm,
  getSchoolData,
  saveField,
  initializeBugsnag,
  notifyError,
  initializeHotjar,
  resolveAppName,
  isMockActive,
  getMockClientCode
};

export default AppService;
