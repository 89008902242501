import {
  BLUR_FIELD,
  VALIDATE_FIELD,
  SAVE_JSON_FORM,
  SAVE_SCHOOL_DATA
} from "./actionTypes";

export const blurField = values => ({
  type: BLUR_FIELD,
  values
});

export const validateField = values => ({
  type: VALIDATE_FIELD,
  values
});

export const saveJsonForm = value => ({
  type: SAVE_JSON_FORM,
  value
});

export const saveSchoolData = value => ({
  type: SAVE_SCHOOL_DATA,
  value
});
