import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Logo from "../../../res/img/logo-waybee.png";

const styles = theme => ({
  PageFooter: {
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    bottom: 0,
    right: 0,
    left: 0,
    height: theme.footer && theme.footer.height ? theme.footer.height : 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  CopyrightWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  FooterLogo: {
    width: 105,
    height: 45,
    padding: 10,
    paddingBottom: 0,
    alignItems: "center",
    display: "inherit"
  },
  FooterText: {
    margin: 0,
    padding: 10,
    paddingBottom: 0,
    fontSize: 14,
    lineHeight: "18px",
    color: "white",
    fontWeight: 500,
    alignItems: "center",
    display: "inherit"
  },
  FooterVersion: {
    position: "absolute",
    right: 0
  }
});

const PageFooter = ({ classes, version }) => {
  return (
    <div className={`PageFooter-root ${classes.PageFooter}`}>
      <button
        type="button"
        style={{
          border: "none",
          padding: "0 !important",
          cursor: "pointer",
          background: "none",
          outline: "none"
        }}
        onClick={() => {
          window.open("https://waybee.com.br");
        }}
      >
        <div className={`CopyrightWrapper-root ${classes.CopyrightWrapper}`}>
          <p className={`FooterText-root ${classes.FooterText}`}>Powered by</p>
          <img
            className={`FooterLogo-root ${classes.FooterLogo}`}
            src={Logo}
            alt="Logo da waybee"
          />
        </div>
      </button>

      <p
        className={`FooterVersion-root ${classes.FooterText} ${classes.FooterVersion}`}
      >
        {`v${version}`}
      </p>
    </div>
  );
};

PageFooter.defaultProps = {
  version: ""
};

PageFooter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstylesm
  version: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(PageFooter);
