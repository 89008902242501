import { api } from "../../utils/api";

const addSubgroup = groupId => {
  return api.post("Enrollments/subgroup", { groupId });
};

const removeSubgroup = subgroupId => {
  return api.delete(`Enrollments/subgroup/${subgroupId}`);
};

const uploadFile = file => {
  const formData = new FormData();
  formData.append("file", file);

  return api.post("Enrollments/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const FormGroupService = {
  uploadFile,
  addSubgroup,
  removeSubgroup
};

export default FormGroupService;
