import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { TextField, withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import compose from "recompose/compose";
import FormGroupService from "../FormGroupPage/FormGroupService";
import FormGroup from "../../components/form/FormGroup/FormGroup";
import { saveJsonForm } from "../../redux/actions";
import Service from "../../AppService";

const mock = [
  {
    id: 2,
    label: "text",
    validations: {
      type: "number"
    }
  },
  {
    id: 1,
    type: "number",
    label: "number",
    validations: {
      type: "number"
    }
  },
  {
    id: 163,
    type: "fetch-select",
    label: "Estado",
    options: {
      url: "https://servicodados.ibge.gov.br/api/v1/localidades/estados",
      response: {
        value: "id",
        label: "nome"
      }
    },
    validations: {
      notNull: true
    }
  },
  {
    id: 164,
    type: "fetch-select",
    label: "Cidade",
    options: {
      header: {
        Origin: "teste"
      },
      url:
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados/:estado/municipios",
      response: {
        value: "id",
        label: "nome"
      },
      filter: [
        {
          id: 163,
          key: "estado",
          required: true
        }
      ]
    },
    validations: {
      notNull: true
    }
  },
  {
    id: 166,
    type: "fetch-select",
    label: "Estado",
    options: {
      url: "http://10.0.1.32/api/get-field-vals/res_country_state/name",
      response: { value: "value", label: "description" }
    },
    validations: {
      notNull: true
    }
  },
  {
    id: 167,
    type: "fetch-select",
    label: "Cidade",
    options: {
      url: "http://10.0.1.32/api/get-field-vals/res_state_city/name",
      response: { value: "value", label: "description" }
    },
    validations: {
      notNull: true
    }
  }
];

class JsonForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: props.json || mock,
      typedJson: props.json || JSON.stringify(mock, null, 1),
      fullJson: "",
      openDialog: false,
      redirectToHome: false
    };
  }

  testUpload = e => {
    FormGroupService.uploadFile(e.target.files[0]);
  };

  saveFullJson = () => {
    const { saveJsonForm: dispatchJsonForm } = this.props;
    const { fullJson } = this.state;

    const jsonTop = JSON.parse(fullJson);

    dispatchJsonForm(this.setStatusProperties(jsonTop));

    this.setState({ redirectToHome: true });
  };

  setStatusProperties = jsonForm => {
    const { theme } = this.props;
    if (jsonForm.formResponsible) {
      jsonForm.formResponsible.forEach(responsible => {
        // eslint-disable-next-line no-param-reassign
        responsible.linkTo = `status/${responsible.id}`;
        // eslint-disable-next-line no-param-reassign
        responsible.statusProperties = Service.getStatusProperties(
          responsible.status,
          theme
        );
      });
    }

    if (jsonForm.formStudent) {
      jsonForm.formStudent.forEach(student => {
        // eslint-disable-next-line no-param-reassign
        student.linkTo = `status/${student.id}`;
        // eslint-disable-next-line no-param-reassign
        student.statusProperties = Service.getStatusProperties(
          student.status,
          theme
        );
      });
    }

    if (jsonForm.formContract) {
      jsonForm.formContract.forEach(contract => {
        // eslint-disable-next-line no-param-reassign
        contract.linkTo = `signature/${contract.id}`;
        // eslint-disable-next-line no-param-reassign
        contract.statusProperties = Service.getStatusProperties(
          contract.status,
          theme
        );
      });
    }

    return jsonForm;
  };

  render() {
    const {
      typedJson,
      json,
      openDialog,
      fullJson,
      redirectToHome
    } = this.state;

    if (redirectToHome) return <Redirect to="/home" />;

    const group = {
      id: 101,
      title: "Documentos",
      icon: "description",
      subgroups: [
        {
          id: 104,
          fields: json
        }
      ]
    };

    return (
      <React.Fragment>
        <Container maxWidth="lg">
          <FormGroup {...group} />
        </Container>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => this.setState({ openDialog: true })}
        >
          Alterar json completo
        </Button>

        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <TextField
            multiline
            style={{ width: "100%" }}
            value={fullJson}
            onChange={e => this.setState({ fullJson: e.target.value })}
          />

          <Button onClick={this.saveFullJson} color="primary">
            Salvar
          </Button>
        </Dialog>

        <Grid
          container
          spacing={3}
          justify="center"
          style={{ marginTop: 100, paddingBottom: 80 }}
        >
          <Grid item xs={3}>
            <Paper style={{ width: "100%" }}>
              <TextField
                multiline
                style={{ width: "100%" }}
                value={typedJson}
                onChange={e => this.setState({ typedJson: e.target.value })}
              />

              <Button
                color="primary"
                onClick={() => this.setState({ json: JSON.parse(typedJson) })}
              >
                Manda!
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

JsonForm.propTypes = {
  json: PropTypes.string,
  saveJsonForm: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

JsonForm.defaultProps = {
  json: ""
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveJsonForm }, dispatch);

export default compose(
  withStyles({}, { withTheme: true }),
  connect(
    null,
    mapDispatchToProps
  )
)(JsonForm);
