import React, { Component } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { TextField, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import InfoDialog from "../../components/dialog/InfoDialog/InfoDialog";
import Button from "../../components/button/Button/Button";
import AccessPageService from "./AccessPageService";
import Alert from "../../components/alert/Alert/Alert";
import AppService from "../../AppService";

const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: [["white"], "!important"]
    }
  },
  AccessPage: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  HeaderContainer: {
    paddingTop: 50
  },
  Container: {
    height: "100%",
    flex: "1 1 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom:
      theme.footer && theme.footer.spacing ? theme.footer.spacing : 130
  },
  Text: {
    color: theme.palette.type === "dark" ? "#fff" : "#000",
    fontSize: 22,
    fontWeight: 400
  },
  ButtonContainer: {
    marginTop: 20
  },
  PageHeader: {
    display: "flex",
    position: "relative"
  },
  HeaderLogo: {
    margin: "24px auto 0",
    minWidth: 260,
    width: "30vw",
    maxWidth: 430,
    maxHeight: 150,
    display: "flex",
    justifyContent: "center"
  }
});

const MaskText = inputProps => {
  const { inputRef, ...other } = inputProps;
  return (
    <IMaskInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      unmask
      mask={inputProps.mask}
    />
  );
};

class AccessPage extends Component {
  state = {
    openDialog: false,
    cpf: "",
    userData: {},
    alertOptions: {}
  };

  onClickSend = async () => {
    const { cpf } = this.state;

    try {
      const userData = await AccessPageService.getUserData({ cpf });
      this.setState({ openDialog: true, userData: userData.data });
    } catch (e) {
      this.openAlert(
        "error",
        "Erro",
        e.response.data.error.message ||
          "Ocorreu um erro ao realizar a autenticação"
      );
    }
  };

  openAlert = (status, title, msg) => {
    this.setState({
      alertOptions: {
        open: true,
        status,
        title,
        msg
      }
    });
  };

  closeAlert = () => {
    this.setState({ alertOptions: { open: false } });
  };

  render() {
    const { classes, schoolData } = this.props;
    const { openDialog, cpf, userData, alertOptions } = this.state;
    const backgroundImage = schoolData.loginBackground;
    const appName = AppService.resolveAppName(schoolData.hostname);
    return (
      <div
        className={`AccessPage-root ${classes.AccessPage}`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className={`HeaderContainer-root ${classes.HeaderContainer}`}>
          <div className={`PageHeader-root ${classes.PageHeader}`}>
            <div className={`HeaderLogo-root ${classes.HeaderLogo}`}>
              <img
                src={schoolData.loginLogo || schoolData.logo}
                alt="Logotipo do colégio"
              />
            </div>
          </div>
        </div>
        <div className={classes.Container}>
          <div>
            <p className={classes.Text}>{`Acesso à área de ${appName}`}</p>

            <TextField
              type="text"
              label="CPF"
              fullWidth
              value={cpf}
              onChange={e => this.setState({ cpf: e.target.value })}
              onBlur={e => this.setState({ cpf: e.target.value })}
              InputProps={{
                inputComponent: MaskText,
                inputProps: {
                  mask: "000.000.000-00"
                }
              }}
            />

            <div className={classes.ButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                href="#"
                onClick={this.onClickSend}
              >
                Enviar
              </Button>
            </div>

            <InfoDialog
              handleClose={() => this.setState({ openDialog: false })}
              open={openDialog}
              title="Solicitação de acesso realizada com sucesso!"
              description={`Enviamos o link de acesso à área de ${appName} para o e-mail ${userData.email}.\n Caso não encontre o e-mail na sua caixa de entrada verifique a sua caixa de SPAM.`}
            />
          </div>
        </div>
        <Alert
          open={alertOptions.open}
          type="swal"
          msg={alertOptions.msg}
          title={alertOptions.title}
          status={alertOptions.status}
          onClose={this.closeAlert}
        />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  schoolData: store.schoolData
});

AccessPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(AccessPage);
