import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon, useTheme, withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Compressor from "compressorjs";
import FileInputService from "./FileInputService";

const styles = {
  FileInput: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    width: "100%",
    height: "100%"
  },
  Button: {
    width: "100%",
    height: "80px",
    border: "2px dashed rgba(0,0,0,.2)",
    fontSize: 16,
    backgroundColor: "white",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 20px"
  },
  Input: {
    position: "absolute",
    left: "0",
    top: "0",
    height: "80px",
    width: "100%",
    opacity: "0"
  },
  ButtonText: {
    fontFamily: "Roboto",
    margin: 0,
    marginRight: 24,
    fontWeight: 300,
    color: "rgb(153,153,153)",
    display: "flex",
    alignItems: "center"
  },
  ButtonIcon: {
    fontSize: 32,
    color: "rgba(0,0,0,.2)"
  },
  Loading: {
    width: "32px !important",
    height: "32px !important",
    color: "rgba(0,0,0,.2)"
  },
  StatusMessage: {
    margin: "4px 0 0 0",
    fontSize: "12px"
  }
};

const FileInput = ({
  classes,
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  const theme = useTheme();
  const { fieldOptions, onChange, onBlur } = props;
  const options = fieldOptions;
  const [loading, changeLoad] = useState(false);
  let { url } = options;

  if (!options.name) throw new Error('Field propertie "name" is required');

  if (!options.msg && options.status === "PENDING")
    options.msg = "Documento em análise";

  let statusColor = "";
  let labelPrefix = "";
  let statusIcon = "";
  switch (options.status) {
    case "NOT_SENT":
      statusColor = "rgba(0,0,0,.2)";
      labelPrefix = "Carregar";
      statusIcon = "archive";
      break;
    case "PENDING":
      statusColor = theme.palette.secondary.main;
      labelPrefix = "Carregado(a)";
      statusIcon = "query_builder";
      break;
    case "APPROVED":
      statusColor = theme.palette.primary.main;
      labelPrefix = options.manualValidation ? "Aprovado(a)" : "Carregado(a)";
      statusIcon = "check_circle_outline";
      break;
    case "ERROR":
      statusColor = "#ec0000";
      labelPrefix = "Erro em";
      statusIcon = "error_outline";
      break;
    default:
      break;
  }

  const upload = async file => {
    const res = await FileInputService.uploadFile(
      file,
      options.label,
      options.id
    );

    if (options.manualValidation) {
      options.status = "PENDING";
    } else {
      options.status = "APPROVED";
    }

    changeLoad(false);
    url = res.data.url; // eslint-disable-line prefer-destructuring

    const targetWrapper = document.createElement("wrapper");
    targetWrapper.value = url;
    targetWrapper.name = field.name;
    targetWrapper.addEventListener("change", async event => {
      onChange(event);
      onBlur();
    });

    const event = new Event("change");
    targetWrapper.dispatchEvent(event);
  };

  const handleChange = async e => {
    changeLoad(true);
    const file = e.target.files[0];
    if (file.type.split("/")[0] === "image") {
      // https://eslint.org/docs/rules/no-new
      // [...] this means that the constructor should be replaced with a function that doesn’t require new to be used
      // Não se aplica nesse caso pois é um método interno de biblioteca
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: 0.5,
        maxWidth: 1024,
        maxHeight: 1024,
        async success(result) {
          try {
            await upload(result);
          } catch (err) {
            console.error(err);
            options.status = "ERROR";
            options.msg = "Ocorreu um erro ao fazer upload do seu arquivo";
            changeLoad(false);
          }
        },
        error(err) {
          console.error(err.message);
          options.status = "ERROR";
          options.msg = "Ocorreu um erro ao fazer upload do seu arquivo";
          changeLoad(false);
        }
      });
    } else {
      await upload(file);
    }
    e.target.value = "";
  };

  const handleClick = () => {
    if (options.status === "APPROVED" && options.manualValidation && url) {
      window.open(url, "_blank");
    }
  };

  return (
    <div className={`FileInput-root ${classes.FileInput}`}>
      <button
        style={{ borderColor: statusColor }}
        className={classes.Button}
        type="button"
        onClick={handleClick}
      >
        <p className={classes.ButtonText}>
          {`${labelPrefix} ${options.label}`}
        </p>
        {loading ? (
          <CircularProgress className={classes.Loading} />
        ) : (
          <Icon style={{ color: statusColor }} className={classes.ButtonIcon}>
            {statusIcon}
          </Icon>
        )}
      </button>
      {!(options.manualValidation && options.status === "APPROVED") && (
        <input className={classes.Input} type="file" onChange={handleChange} />
      )}
      {options.msg && (
        <p style={{ color: statusColor }} className={classes.StatusMessage}>
          {options.msg}
        </p>
      )}
    </div>
  );
};

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
FileInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired // https://material-ui.com/pt/styles/advanced/#withstyles,
};

export default withStyles(styles)(FileInput);
