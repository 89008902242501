import React from "react";
import MaterialButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const styles = {
  CustomButton: {
    color: "white",
    fontSize: 17,
    textTransform: "capitalize",
    height: 44,
    fontWeight: 300,
    boxShadow: "none"
  }
};

const Button = props => {
  const { classes } = props;
  return (
    <MaterialButton {...props} className={classes.CustomButton} classes={{}} />
  );
};

Button.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles,
  ...MaterialButton.propTypes
};

export default withStyles(styles)(Button);
