import "./App.scss";
import React, { Component } from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import compose from "recompose/compose";
import HomeMatriculas from "./pages/HomePage/HomePage";
import ContractPage from "./pages/ContractPage/ContractPage";
import FormStatus from "./pages/FormStatusPage/FormStatusPage";
import FormGroup from "./pages/FormGroupPage/FormGroupPage";
import AccessPage from "./pages/AccessPage/AccessPage";
import RoutePage from "./pages/RoutePage/RoutePage";
import WorkflowPage from "./pages/WorkflowPage/WorkflowPage";
import Demo from "./pages/DemoPage/Demo";
import { saveJsonForm, saveSchoolData, blurField } from "./redux/actions";
import Service from "./AppService";
import PageFooter from "./components/page/PageFooter/PageFooter";
import Alert from "./components/alert/Alert/Alert";

const { REACT_APP_ENV, REACT_APP_VERSION } = process.env;

const styles = () => ({
  Loading: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      alertOptions: {
        open: false,
        status: "",
        title: "",
        msg: ""
      }
    };
  }

  async componentDidMount() {
    const {
      saveJsonForm: dispatchJsonForm,
      saveSchoolData: dispatchSchoolData
    } = this.props;

    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (token) {
      localStorage.setItem("token", token);
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    try {
      const schoolData = await Service.getSchoolData();
      dispatchSchoolData(schoolData.data);
    } catch (e) {
      Service.notifyError(e);
      console.error(e);
      this.openAlert(
        "error",
        "Erro",
        "Ocorreu um erro ao buscar os dados do colégio"
      );
    }

    if (window.location.pathname !== "/login") {
      try {
        const response = await Service.getJsonForm();
        const jsonForm = this.setStatusProperties(response.data);
        this.storeFieldsValues(jsonForm);
        localStorage.setItem("enrollmentId", jsonForm.id);
        dispatchJsonForm(jsonForm);
      } catch (e) {
        // Ignores error treatment on 401 response, as the user will be redirected
        if (e.response && e.response.status === 401) {
          return;
        }
        Service.notifyError(e);
        console.error(e);
        this.openAlert(
          "error",
          "Erro",
          "Ocorreu um erro ao buscar os dados do formulário"
        );
      }
    }

    this.setState({ loading: false });
  }

  storeFieldsValues = jsonForm => {
    const { saveField } = this.props;
    const fieldsObj = {};

    const getValue = form => {
      if (form.groups)
        form.groups.forEach(group => {
          if (group.subgroups)
            group.subgroups.forEach(subgroup => {
              if (subgroup.fields)
                subgroup.fields.forEach(field => {
                  if (field.value !== undefined)
                    fieldsObj[`field${field.id}`] = field.value;
                });
            });
        });
    };

    if (jsonForm.formResponsible) {
      jsonForm.formResponsible.forEach(responsible => {
        if (responsible.forms)
          responsible.forms.forEach(form => {
            getValue(form);
          });
      });
    }

    if (jsonForm.formStudent) {
      jsonForm.formStudent.forEach(student => {
        if (student.forms)
          student.forms.forEach(form => {
            getValue(form);
          });
      });
    }

    if (jsonForm.formContract) {
      jsonForm.formContract.forEach(contract => {
        if (contract.workflow)
          contract.workflow.forEach(step => {
            getValue(step);
          });
      });
    }

    saveField(fieldsObj);
  };

  setStatusProperties = jsonForm => {
    const { schoolData } = this.props;
    const appTheme = createMuiTheme(schoolData.theme);

    if (jsonForm.formResponsible) {
      jsonForm.formResponsible.forEach(responsible => {
        // eslint-disable-next-line no-param-reassign
        responsible.linkTo = `status/${responsible.id}`;
        // eslint-disable-next-line no-param-reassign
        responsible.statusProperties = Service.getStatusProperties(
          responsible.status,
          appTheme
        );
      });
    }

    if (jsonForm.formStudent) {
      jsonForm.formStudent.forEach(student => {
        // eslint-disable-next-line no-param-reassign
        student.linkTo = `status/${student.id}`;
        // eslint-disable-next-line no-param-reassign
        student.statusProperties = Service.getStatusProperties(
          student.status,
          appTheme
        );
      });
    }

    if (jsonForm.formContract) {
      jsonForm.formContract.forEach(contract => {
        // eslint-disable-next-line no-param-reassign
        contract.linkTo = `signature/${contract.id}`;
        // eslint-disable-next-line no-param-reassign
        contract.statusProperties = Service.getStatusProperties(
          contract.status,
          appTheme
        );
      });
    }

    return jsonForm;
  };

  openAlert = (status, title, msg) => {
    this.setState({
      alertOptions: {
        open: true,
        status,
        title,
        msg
      }
    });
  };

  closeAlert = () => {
    this.setState({ alertOptions: { open: false } });
  };

  renderEmptyPathRoute = () => {
    if (REACT_APP_ENV === "develop" || REACT_APP_ENV === "staging")
      return <Route path="/" exact component={RoutePage} />;
    if (localStorage.getItem("token")) {
      return <Redirect to="/home" />;
    }

    return <Redirect to="/login" />;
  };

  render() {
    const { loading, alertOptions } = this.state;
    const { classes, schoolData } = this.props;
    const appTheme = createMuiTheme(schoolData.theme);
    console.log(appTheme);
    return (
      <MuiThemeProvider theme={appTheme}>
        <div className="app">
          {loading ? (
            <div className={classes.Loading}>
              <CircularProgress />
            </div>
          ) : (
            <BrowserRouter>
              <Switch>
                {REACT_APP_ENV === "develop" ? (
                  <Route path="/demo" exact component={Demo} />
                ) : null}

                <Route
                  exact
                  path="/"
                  render={() => this.renderEmptyPathRoute()}
                />
                <Route
                  path="/login"
                  exact
                  render={props => (
                    <MuiThemeProvider
                      theme={createMuiTheme({
                        palette: {
                          ...schoolData.theme.palette,
                          ...{
                            type: schoolData.loginBackground ? "dark" : "light"
                          }
                        }
                      })}
                    >
                      <AccessPage {...props} />
                    </MuiThemeProvider>
                  )}
                />
                <Route path="/home" exact component={HomeMatriculas} />
                <Route
                  path="/contract/:studentId"
                  exact
                  component={ContractPage}
                />
                <Route
                  path="/status/:idGroup([0-9]*)"
                  exact
                  component={FormStatus}
                />
                <Route
                  path="/status/:idGroup([0-9]*)/form/:idForm([0-9]*)"
                  exact
                  component={FormGroup}
                />
                <Route
                  path="/signature/:idGroup([0-9]*)"
                  exact
                  component={WorkflowPage}
                />
              </Switch>
            </BrowserRouter>
          )}
        </div>
        {!loading && <PageFooter version={REACT_APP_VERSION} />}
        <Alert
          open={alertOptions.open}
          type="swal"
          msg={alertOptions.msg}
          title={alertOptions.title}
          status={alertOptions.status}
          onClose={this.closeAlert}
        />
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  saveJsonForm: PropTypes.func.isRequired,
  saveSchoolData: PropTypes.func.isRequired,
  saveField: PropTypes.func.isRequired,
  schoolData: PropTypes.shape({
    logo: PropTypes.string,
    theme: PropTypes.object,
    loginBackground: PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired // https://material-ui.com/pt/styles/advanced/#withstyles
};

const mapStateToProps = store => ({
  schoolData: store.schoolData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { saveJsonForm, saveSchoolData, saveField: blurField },
    dispatch
  );

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);
