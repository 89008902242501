import { api } from "../../../utils/api";

const uploadFile = (file, fieldLabel, fieldId) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fieldLabel", fieldLabel);
  formData.append("fieldId", fieldId);
  formData.append("enrollmentId", localStorage.getItem("enrollmentId"));

  return api.post("Enrollments/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const FileInputService = {
  uploadFile
};

export default FileInputService;
