import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";

const MaskText = inputProps => {
  const { inputRef, ...other } = inputProps;
  return (
    <IMaskInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      unmask
      mask={inputProps.mask}
    />
  );
};

const TextInput = ({
  field, // { name, value, onChange, onBlur }
  form,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { fieldOptions, onChange, onBlur, value } = props;
  const options = fieldOptions;
  if (!options.name) throw new Error('Field propertie "name" is required');

  const checkOnBlur = () => {
    if (touched[options.name] && Boolean(errors[options.name])) {
      return;
    }
    onBlur();
  };
  return (
    <TextField
      id={options.name}
      disabled={Boolean(options.disabled)}
      name={options.name}
      type={options.type}
      helperText={touched[options.name] ? errors[options.name] : ""}
      error={touched[options.name] && Boolean(errors[options.name])}
      label={options.label}
      value={value}
      onBlur={checkOnBlur}
      onChange={onChange}
      multiline={fieldOptions.type === "textarea"}
      fullWidth
      InputLabelProps={{ shrink: !!value }}
      InputProps={
        options.mask && {
          inputComponent: MaskText,
          inputProps: {
            mask: options.mask,
            value
          }
        }
      }
    />
  );
};

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
TextInput.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default TextInput;
