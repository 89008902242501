import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import RoundedAvatar from "../../images/RoundedAvatar/RoundedAvatar";

const styles = theme => ({
  InfoListItem: {
    padding: "13px 20px",
    display: "flex",
    backgroundColor: props => props.highlight && theme.palette.primary.light
  },
  InfoTexts: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: 44,
    marginLeft: props => props.showPhoto && 20,
    "@global": {
      p: {
        margin: 0
      }
    }
  },
  PersonName: {
    fontWeight: 300,
    fontSize: 16,
    color: "black"
  },
  PersonType: {
    fontWeight: 400,
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.5)"
  },
  IconContainer: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center"
  },
  Icon: {
    color: props => props.iconColor
  },
  IconText: {
    display: "inline-block",
    margin: "0 0 0 14px",
    color: props => props.iconColor,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  Link: {
    display: "block",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer"
    }
  }
});

const InfoListItem = ({
  classes,
  showPhoto,
  photo,
  icon,
  iconText,
  name,
  type,
  hasLink,
  linkTo,
  status
}) => {
  const Wrapper = hasLink && status !== "SIGNED" ? Link : Fragment;
  const wrapperProps =
    hasLink && status !== "SIGNED"
      ? { to: linkTo, className: classes.Link }
      : {};
  return (
    <Wrapper {...wrapperProps}>
      <div className={`InfoListItem-root ${classes.InfoListItem}`}>
        {showPhoto && <RoundedAvatar icon="person" imageSrc={photo} />}

        <div className={`InfoTexts-root ${classes.InfoTexts}`}>
          <p className={`PersonName-root ${classes.PersonName}`}>{name}</p>
          <p className={`PersonType-root ${classes.PersonType}`}>{type}</p>
        </div>

        {icon && (
          <div className={`IconContainer-root ${classes.IconContainer}`}>
            <Icon className={classes.Icon}>{icon}</Icon>
            <p className={`IconText-root ${classes.IconText}`}>{iconText}</p>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

InfoListItem.defaultProps = {
  photo: undefined,
  icon: undefined,
  iconColor: "#000",
  iconText: "",
  showPhoto: false,
  highlight: true,
  hasLink: false,
  linkTo: "/",
  status: ""
};

InfoListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  showPhoto: PropTypes.bool,
  photo: PropTypes.string,
  icon: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  iconColor: PropTypes.string, // Prop sendo usada nos styles
  iconText: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  highlight: PropTypes.bool, // Prop sendo usada nos styles
  hasLink: PropTypes.bool,
  linkTo: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(InfoListItem);
