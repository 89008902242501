import {
  BLUR_FIELD,
  VALIDATE_FIELD,
  SAVE_JSON_FORM
} from "../actions/actionTypes";

export const jsonFormReducer = (state = {}, action) => {
  if (action.type === BLUR_FIELD) {
    return {
      ...state,
      ...action.values
    };
  }
  return state;
};

export const renderInfosReducer = (state = {}, action) => {
  if (action.type === VALIDATE_FIELD) {
    return {
      ...state,
      ...action.values
    };
  }
  return state;
};

export const saveJsonForm = (state = {}, action) => {
  if (action.type === SAVE_JSON_FORM) {
    return {
      ...action.value
    };
  }
  return state;
};
