import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button, Icon as MuiIcon } from "@material-ui/core";

const Container = styled.div`
  padding: 12px;
`;

const Icon = styled(MuiIcon)`
  margin-right: 5px;
`;

const Link = ({ href, label, variant, color, icon }) => {
  return (
    <Container>
      <Button href={href} target="_blank" variant={variant} color={color}>
        {icon && <Icon>{icon}</Icon>}
        {label}
      </Button>
    </Container>
  );
};

Link.defaultProps = {
  label: "",
  variant: "text",
  color: "default",
  icon: ""
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  icon: PropTypes.string
};

export default Link;
