import React from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import FormHelperText from "@material-ui/core/FormHelperText";

const textLabels = {
  body: {
    noMatch: "Nenhum registro encontrado",
    toolTip: "Ordenar",
    columnHeaderTooltip: column => `Ordenar por ${column.label}`
  },
  pagination: {
    next: "Próxima página",
    previous: "Página anterior",
    rowsPerPage: "Linhas por página:",
    displayRows: "de"
  },
  toolbar: {
    search: "Buscar",
    downloadCsv: "Baixar CSV",
    print: "Imprimir",
    viewColumns: "Ver colunas",
    filterTable: "Filtrar tabela"
  },
  filter: {
    all: "Tabela",
    title: "FILTROS",
    reset: "LIMPAR"
  },
  viewColumns: {
    title: "Mostrar colunas",
    titleAria: "Mostrar/Esconder colunas da tabela"
  },
  selectedRows: {
    text: "linha(s) selecionada(s)",
    delete: "Deletar",
    deleteAria: "Deletar linha(s) selecionada(s)"
  }
};

const DataTable = ({ field, form: { touched, errors }, ...props }) => {
  const { fieldOptions: properties, value } = props;
  if (!properties.options) properties.options = {};

  const handleChange = async (currentRowsSelected, allRowsSelected) => {
    const selectedValues = [];
    allRowsSelected.forEach(row => {
      const findedValue = properties.data[row.index].value;
      selectedValues.push(findedValue);
    });

    const targetWrapper = document.createElement("wrapper");
    targetWrapper.value =
      selectedValues.length > 0 ? selectedValues : undefined;
    targetWrapper.name = field.name;
    targetWrapper.addEventListener("change", async event => {
      props.onChange(event);
      props.onBlur();
    });

    const event = new Event("change");
    targetWrapper.dispatchEvent(event);
  };

  if (properties.options.footer === false) {
    properties.options.customFooter = () => null;
  }
  properties.options.textLabels = textLabels;
  properties.options.elevation = 0;
  properties.options.onRowsSelect = handleChange;
  properties.options.customToolbarSelect = () => null;
  properties.options.responsive = "scrollMaxHeight";

  const valuesToCheck = [];
  if (value) {
    value.forEach(val => {
      properties.data.forEach((row, idx) => {
        if (row.value === val) {
          valuesToCheck.push(idx);
        }
      });
    });
  }
  properties.options.rowsSelected = valuesToCheck;

  return (
    <React.Fragment>
      <MUIDataTable
        title={properties.label}
        data={properties.data}
        columns={properties.columns}
        options={properties.options}
      />
      {touched[properties.name] && Boolean(errors[properties.name]) && (
        <FormHelperText className="Mui-error">
          {errors[properties.name]}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

DataTable.defaultProps = {
  value: ""
};

/**
 * A estrutura das propriedas permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
DataTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  field: PropTypes.object.isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any
};

export default DataTable;
