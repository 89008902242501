import api from "axios";

const getData = url => {
  return api.get(url);
};

const FetchSelectService = {
  getData
};

export default FetchSelectService;
