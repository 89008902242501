import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

const styles = {
  PageHeader: {
    boxShadow: ({ hasShadow }) =>
      hasShadow ? "1px 1px 5px 0 rgba(0,0,0,0.27)" : "none",
    display: "flex",
    position: "relative"
  },
  HeaderLogo: {
    height: 64,
    margin: "24px auto"
  }
};

const PageHeaderComponent = ({ classes, logo }) => {
  return (
    <div className={`PageHeader-root ${classes.PageHeader}`}>
      <img
        className={`HeaderLogo-root ${classes.HeaderLogo}`}
        src={logo}
        alt="Logotipo do colégio"
      />
    </div>
  );
};

PageHeaderComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  logo: PropTypes.string.isRequired
};

const PageHeader = withStyles(styles, { withTheme: true })(PageHeaderComponent);

PageHeader.defaultProps = {
  hasShadow: true
};

PageHeader.propTypes = {
  hasShadow: PropTypes.bool // Usado nos styles
};

export default PageHeader;
