import axios from "axios";

const { REACT_APP_API } = process.env;
const $apiUrl = REACT_APP_API;

const urlApi = {
  value: `${$apiUrl}api/`
};

const api = axios.create({
  baseURL: urlApi.value,
  responseType: "json",
  headers: { "Content-Type": "application/json" },
  timeout: 15000
});

api.interceptors.request.use(config => {
  const erpAccessToken = localStorage.getItem("token"); // TODO

  if (erpAccessToken) {
    config.headers.erpAccessToken = erpAccessToken; // eslint-disable-line no-param-reassign
  }

  return config;
});

// Intercepta respostas especificas. No caso a de 401
api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Executa redirecionamento a não ser que o erro 401 venha de uma tentativa de login.
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      !window.location.pathname.includes("login")
    ) {
      localStorage.clear();
      window.location.replace("/login");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export { api };
export default urlApi;
