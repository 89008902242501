import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import PageTitle from "../../components/page/PageTitle/PageTitle";
import InfoList from "../../components/list/InfoList/InfoList";
import Service from "../../AppService";
import NavigationBack from "../../components/page/NavigationBack/NavigationBack";
import PageHeader from "../../components/page/PageHeader/PageHeader";

const styles = theme => ({
  FormStatus: {
    [theme.breakpoints.up("sm")]: {
      margin: 20
    }
  },
  Container: {
    padding: 0,
    paddingBottom:
      theme.footer && theme.footer.spacing ? theme.footer.spacing : 130
  }
});

class FormStatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formInfos: {}
    };
  }

  componentWillMount() {
    const formInfo = this.setStatusProperties(this.findForm());
    this.setState({ formInfos: formInfo });
  }

  setStatusProperties = jsonForm => {
    const { theme, match } = this.props;
    const { idGroup } = match.params;

    jsonForm.forms.forEach(form => {
      const statusProperties = Service.getStatusProperties(
        form.status,
        theme,
        form.msg
      );
      // eslint-disable-next-line no-param-reassign
      form.list = [
        {
          id: form.id,
          name: "Clique aqui para editar",
          type: statusProperties.msg,
          statusProperties,
          linkTo: `/status/${idGroup}/form/${form.id}`
        }
      ];
    });
    return jsonForm;
  };

  findForm = () => {
    const { jsonForm, match } = this.props;
    const { idGroup } = match.params;

    let findedForm = "";
    if (jsonForm.formStudent) {
      findedForm = jsonForm.formStudent.find(
        form => form.id.toString() === idGroup
      );
    }

    if (!findedForm) {
      findedForm = jsonForm.formResponsible.find(
        form => form.id.toString() === idGroup
      );
    }
    if (findedForm) {
      return findedForm;
    }
    throw new Error(`Formulário não encontrado para o id ${idGroup}`);
  };

  render() {
    const { classes, schoolData } = this.props;
    const { formInfos } = this.state;

    return (
      <Fragment>
        <PageHeader logo={schoolData.logo} />
        <PageTitle title={formInfos.name} hasPhoto photo={formInfos.photo} />
        <NavigationBack backTo="/home" />
        <Container maxWidth="lg" className={classes.Container}>
          <div className={classes.FormStatus}>
            {formInfos.forms.map(form => (
              <InfoList
                key={form.id}
                title={form.name}
                headerIcon={form.icon}
                personsList={form.list}
              />
            ))}
          </div>
        </Container>
      </Fragment>
    );
  }
}

FormStatusPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  // eslint-disable-next-line react/forbid-prop-types
  jsonForm: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired, // https://reacttraining.com/react-router/web/example/url-params
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired // https://material-ui.com/customization/theming/
};

const mapStateToProps = store => ({
  jsonForm: store.jsonForm,
  schoolData: store.schoolData
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(FormStatusPage);
