import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, TextField, withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveJsonForm, saveSchoolData } from "../../redux/actions";
import Service from "../../AppService";

/* eslint react/prefer-stateless-function:0 */
class RoutePage extends Component {
  state = {
    pass: "",
    linkToHome: false
  };

  setStatusProperties = jsonForm => {
    const { theme } = this.props;
    if (jsonForm.formResponsible) {
      jsonForm.formResponsible.forEach(responsible => {
        // eslint-disable-next-line no-param-reassign
        responsible.linkTo = `status/${responsible.id}`;
        // eslint-disable-next-line no-param-reassign
        responsible.statusProperties = Service.getStatusProperties(
          responsible.status,
          theme
        );
      });
    }

    if (jsonForm.formStudent) {
      jsonForm.formStudent.forEach(student => {
        // eslint-disable-next-line no-param-reassign
        student.linkTo = `status/${student.id}`;
        // eslint-disable-next-line no-param-reassign
        student.statusProperties = Service.getStatusProperties(
          student.status,
          theme
        );
      });
    }

    if (jsonForm.formContract) {
      jsonForm.formContract.forEach(contract => {
        // eslint-disable-next-line no-param-reassign
        contract.linkTo = `signature/${contract.id}`;
        // eslint-disable-next-line no-param-reassign
        contract.statusProperties = Service.getStatusProperties(
          contract.status,
          theme
        );
      });
    }
    return jsonForm;
  };

  clickAccess = async () => {
    const { pass } = this.state;

    if (!pass) {
      alert("Insira uma senha");
      return;
    }

    if (pass === "@teste-cal") {
      localStorage.setItem("activeMock", "1");
    } else if (pass === "@teste-sabin") {
      localStorage.setItem("activeMock", "2");
    } else {
      alert("Senha incorreta");
      return;
    }

    const {
      saveJsonForm: dispatchJsonForm,
      saveSchoolData: dispatchSchoolData
    } = this.props;

    const response = await Service.getJsonForm();
    const jsonForm = this.setStatusProperties(response.data);
    localStorage.setItem("enrollmentId", jsonForm.id);
    dispatchJsonForm(jsonForm);

    const schoolData = await Service.getSchoolData();
    dispatchSchoolData(schoolData.data);

    this.setState({ linkToHome: true });
  };

  render() {
    const { pass, linkToHome } = this.state;

    if (linkToHome) {
      return <Redirect to="/home" />;
    }

    return (
      <React.Fragment>
        <TextField
          label="Senha"
          type="password"
          value={pass}
          onChange={e => this.setState({ pass: e.target.value })}
        />

        <Button href="#" onClick={this.clickAccess}>
          Acessar
        </Button>

        {/* <Link to="/home"> */}
        {/*  <Button href="#">Acessar</Button> */}
        {/* </Link> */}
        <br />
        <Divider component="span" />
        <Link to="/demo">
          <Button href="#">Acessar tela de Demo</Button>
        </Link>
      </React.Fragment>
    );
  }
}

RoutePage.propTypes = {
  saveJsonForm: PropTypes.func.isRequired,
  saveSchoolData: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ saveJsonForm, saveSchoolData }, dispatch);

export default compose(
  withStyles({}, { withTheme: true }),
  connect(
    null,
    mapDispatchToProps
  )
)(RoutePage);
