import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InfoListItem from "../InfoListItem/InfoListItem";

const Container = styled.div`
  width: 100%;
`;

const Description = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 29px 20px;
`;

const SimpleList = ({ list }) => {
  return (
    <Container>
      {list.map((listItem, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={i}>
          <Description>{listItem.description}</Description>
          {listItem.items.map((item, idx) => (
            <InfoListItem
              // eslint-disable-next-line react/no-array-index-key
              key={idx} // Usando index como array pois o componente n tem alteracão, é somente para exibição de dados
              showPhoto={listItem.showPhoto}
              photo={item.photo}
              name={item.title}
              type={item.description}
            />
          ))}
        </span>
      ))}
    </Container>
  );
};

SimpleList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      showPhoto: PropTypes.bool,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          photo: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string
        })
      ).isRequired
    })
  ).isRequired
};

export default SimpleList;
