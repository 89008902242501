import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Service from "./FetchSelectService";

class FetchSelect extends Component {
  state = {
    optionsData: [],
    localError: ""
  };

  componentDidMount() {
    const { storedValues } = this.props;
    const url = this.getUrl(storedValues);
    if (url) {
      this.getData(url);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { storedValues } = this.props;
    if (nextProps.storedValues !== storedValues) {
      const url = this.getUrl(nextProps.storedValues);
      if (url) {
        this.getData(url);
      }
    }
  }

  formatData = data => {
    const { fieldOptions: properties } = this.props;
    const resConfig = properties.options.response;
    return data.map(item => ({
      value: item[resConfig.value],
      label: item[resConfig.label]
    }));
  };

  getData = async url => {
    try {
      const res = await Service.getData(url);
      const formattedData = this.formatData(res.data);
      this.setState({ optionsData: formattedData });
    } catch (e) {
      console.error(e);
      this.setState({ localError: "Ocorreu um erro ao carregar as opções" });
    }
  };

  getUrl = storedValues => {
    const { fieldOptions: properties } = this.props;
    const fieldFilter = properties.options.filter;
    let url = `${properties.options.url}`;

    if (fieldFilter) {
      const requiredFilter = fieldFilter.filter(item => item.required);
      if (requiredFilter) {
        let canSearch = true;
        requiredFilter.forEach(filter => {
          const filterValue = storedValues[`field${filter.id}`];
          if (filterValue) {
            url = properties.options.url.replace(`:${filter.key}`, filterValue);
          } else {
            canSearch = false;
          }
        });
        if (!canSearch) return "";
      }
    }
    return url;
  };

  defineValue = value => {
    const { optionsData } = this.state;
    const foundData = optionsData.find(data => data.value === value);
    return foundData || { label: "" };
  };

  handleChange = async (e, selectedItem) => {
    const { field, onChange, onBlur } = this.props;

    const targetWrapper = document.createElement("wrapper");
    targetWrapper.value = selectedItem ? selectedItem.value : "";
    targetWrapper.name = field.name;
    targetWrapper.addEventListener("change", async event => {
      await onChange(event);
      onBlur();
    });

    const event = new Event("change");
    targetWrapper.dispatchEvent(event);
  };

  render() {
    const {
      fieldOptions: properties,
      value: fieldValue,
      field,
      form: { touched, errors }
    } = this.props;
    const { optionsData, localError } = this.state;
    return (
      <FormControl style={{ minWidth: "100%" }} component="div">
        <Autocomplete
          options={optionsData}
          getOptionLabel={option => option.label}
          value={this.defineValue(fieldValue)}
          onChange={this.handleChange}
          renderInput={params => {
            params.inputProps.autoComplete = "off"; // eslint-disable-line no-param-reassign
            return (
              <TextField
                {...params}
                id={properties.name}
                type="text"
                name={field.name}
                error={localError || errors[properties.name]}
                helperText={
                  (localError || touched[properties.name]) &&
                  (localError || errors[properties.name])
                }
                label={properties.label}
                fullWidth
              />
            );
          }}
        />
      </FormControl>
    );
  }
}

/**
 * A estrutura das props permitidas estão ducumentadas em:
 * http://10.0.1.12:4000/projetos/matriculas-web/estrutura-json
 */
FetchSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldOptions: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  // eslint-disable-next-line react/forbid-prop-types
  storedValues: PropTypes.object.isRequired
};

const mapStateToProps = store => ({
  storedValues: store.jsonFormState
});

export default connect(mapStateToProps)(FetchSelect);
