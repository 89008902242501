import localize from "ajv-i18n/localize/pt-BR";
import { Store } from "../redux/store";

const getErrorsMessage = errors => {
  if (!(errors && errors.length)) return;
  localize(errors);
  for (let i = 0; i < errors.length; i += 1) {
    const e = errors[i];
    let out;
    switch (e.keyword) {
      case "equalsTo":
        out = "Deve ser igual a outro campo";
        break;
      case "notNull":
        out = "O campo é obrigatório";
        break;
      // no default
    }

    if (out) {
      e.message = out;
    }
  }
};

const setCustomKeywords = ajv => {
  ajv.addKeyword("equalsTo", {
    compile(keyValue) {
      return data => {
        const state = Store.getState().jsonFormState;
        return state[`field${keyValue}`] === data;
      };
    }
  });
  ajv.addKeyword("notNull", {
    compile(keyValue) {
      return data => {
        return (
          keyValue === true &&
          data !== undefined &&
          data !== null &&
          data !== ""
        );
      };
    }
  });
};

const AjvUtils = {
  getErrorsMessage,
  setCustomKeywords
};

export default AjvUtils;
