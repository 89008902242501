import { api } from "../../utils/api";
import AppService from "../../AppService";

const isMockActive = AppService.isMockActive();

const nextStep = (buttonId, formContractId, workflowId, step) => {
  if (isMockActive) {
    return new Promise(resolve => resolve());
  }
  return api.post("Enrollments/nextStep", {
    buttonId,
    formContractId,
    workflowId,
    step
  });
};

const WorkflowService = {
  nextStep
};

export default WorkflowService;
