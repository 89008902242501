import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";

const styles = {
  LabelContainer: {
    height: 60,
    display: "flex",
    alignItems: "center"
  },
  Label: {
    color: "rgba(0,0,0,.5)",
    margin: 0,
    fontSize: 14
  }
};

const FormLabel = ({ classes, text }) => (
  <div className={classes.LabelContainer}>
    <p className={`Label-root ${classes.Label}`}>{text}</p>
  </div>
);

FormLabel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  text: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(FormLabel);
