import React, { Component, Fragment } from "react"; // eslint-disable-line no-param-reassign
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import InfoList from "../../components/list/InfoList/InfoList";
import PageTitle from "../../components/page/PageTitle/PageTitle";
import PageHeader from "../../components/page/PageHeader/PageHeader";

const styles = theme => ({
  InfoListContainer: {
    [theme.breakpoints.up("sm")]: {
      margin: 20
    }
  },
  Container: {
    padding: 0,
    paddingBottom:
      theme.footer && theme.footer.spacing ? theme.footer.spacing : 130
  }
});

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonForm: {}
    };
  }

  componentWillMount() {
    const { jsonForm } = this.props;
    this.setState({ jsonForm });
  }

  render() {
    const { classes, schoolData } = this.props;
    const { jsonForm } = this.state;

    return (
      <Fragment>
        <PageHeader logo={schoolData.logo} />
        <PageTitle title={jsonForm.title} />
        <Container maxWidth="lg" className={classes.Container}>
          <div className={classes.InfoListContainer}>
            {jsonForm.formResponsible && (
              <InfoList
                title={jsonForm.formResponsibleTitle}
                headerIcon="how_to_reg"
                personsList={jsonForm.formResponsible}
                showPhoto
              />
            )}
            {jsonForm.formStudent && (
              <InfoList
                title={jsonForm.formStudentTitle}
                headerIcon="how_to_reg"
                personsList={jsonForm.formStudent}
                showPhoto
              />
            )}
            {jsonForm.formContract && (
              <InfoList
                title={jsonForm.formContractTitle}
                headerIcon="description"
                personsList={jsonForm.formContract}
                showPhoto
              />
            )}
          </div>
        </Container>
      </Fragment>
    );
  }
}

HomePage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  jsonForm: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired // https://material-ui.com/pt/styles/advanced/#withstyles
};

const mapStateToProps = store => ({
  jsonForm: store.jsonForm,
  schoolData: store.schoolData
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(HomePage);
