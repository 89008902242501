import React, { Component, Fragment } from "react"; // eslint-disable-line no-param-reassign
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import PageTitle from "../../components/page/PageTitle/PageTitle";
import PageHeader from "../../components/page/PageHeader/PageHeader";
import Clicksign from "../../res/js/embedded";
import Service from "./ContractService";
import Alert from "../../components/alert/Alert/Alert";
import NavigationBack from "../../components/page/NavigationBack/NavigationBack";
import AppService from "../../AppService";

const styles = theme => ({
  Container: {
    padding: 0,
    paddingBottom:
      theme.footer && theme.footer.spacing ? theme.footer.spacing : 130
  },
  ContractContainer: {
    height: "500px",
    [theme.breakpoints.up("sm")]: {
      margin: 20
    }
  },
  Loading: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

class ContractPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonForm: {},
      alertOptions: {},
      loading: true
    };
  }

  componentWillMount() {
    const { jsonForm } = this.props;
    this.setState({ jsonForm });
  }

  async componentDidMount() {
    const { match } = this.props;
    let widget;

    if (widget) {
      widget.unmount();
    }

    try {
      const clickSignResponse = await Service.getClickSignKey(
        match.params.studentId
      );
      widget = new Clicksign(clickSignResponse.data.key);
      widget.endpoint =
        process.env.REACT_APP_ENV === "production"
          ? "https://app.clicksign.com"
          : "https://sandbox.clicksign.com";
      widget.origin = window.location.href;

      setTimeout(() => {
        widget.mount("contract-container");
        this.setState({ loading: false });
      }, 15000);

      widget.on("loaded", () => {
        this.setState({ loading: false });
      });

      widget.on("signed", () => {
        this.openAlert(
          "Success",
          "Contrato assinado com sucesso! Você receberá um e-mail com o contrato assinado, assim que o processo for validado pelo colégio"
        );
      });
    } catch (e) {
      if (!e.isAxiosError || (e.response && e.response.status !== 400)) {
        AppService.notifyError(e);
      }
      this.setState({ loading: false });
      let errorMessage = "";
      if (
        e.isAxiosError &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.message
      ) {
        errorMessage = e.response.data.error.message;
      }
      this.openAlert(
        "error",
        "Ocorreu um erro ao gerar o documento para assinar.",
        errorMessage
      );
    }
  }

  openAlert = (status, title, msg) => {
    this.setState({
      alertOptions: {
        open: true,
        status,
        title,
        msg
      }
    });
  };

  closeAlert = () => {
    this.setState({ alertOptions: { open: false } });
    window.location.pathname = "home";
  };

  render() {
    const { classes, schoolData } = this.props;
    const { jsonForm, alertOptions, loading } = this.state;

    return (
      <Fragment>
        <PageHeader logo={schoolData.logo} />
        <PageTitle title={jsonForm.title} />
        <NavigationBack backTo="/home" />
        {loading && (
          <div>
            <p style={{ textAlign: "center" }} className={classes.Loading}>
              Por gentileza aguarde alguns segundos, estamos carregando seu
              contrato!
            </p>
            <div className={classes.Loading}>
              <CircularProgress />
            </div>
          </div>
        )}
        <Container maxWidth="lg" className={classes.Container}>
          <div className={classes.ContractContainer} id="contract-container" />
        </Container>
        <Alert
          open={alertOptions.open}
          type="swal"
          msg={alertOptions.msg}
          title={alertOptions.title}
          status={alertOptions.status}
          onClose={this.closeAlert}
        />
      </Fragment>
    );
  }
}

ContractPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  jsonForm: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired, // http://10.0.1.12:4000/edit/projetos/matriculas-web/estrutura-json
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired // https://reacttraining.com/react-router/web/example/url-params
};

const mapStateToProps = store => ({
  jsonForm: store.jsonForm,
  schoolData: store.schoolData
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(ContractPage);
