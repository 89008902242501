import React, { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Button, Icon, withStyles } from "@material-ui/core";

const styles = theme => ({
  InfoListFooter: {
    borderTop: "1px solid rgb(225,225,225)",
    padding: "18px 20px",
    borderRadius: 0
  },
  BtnLabel: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  BtnIcon: {
    color: theme.palette.primary.main,
    fontSize: 20
  },
  BtnText: {
    margin: "0 0 0 12px",
    color: theme.palette.primary.main,
    fontSize: 12,
    textTransform: "capitalize"
  },
  Loading: {
    color: theme.palette.primary.main,
    width: "20px !important",
    height: "20px !important"
  }
});

const InfoListFooter = ({
  classes,
  btnText,
  btnIcon,
  btnAction,
  btnLink,
  onClickAddSubgroup
}) => {
  const [loading, setLoad] = useState(false);

  const handleClick = async () => {
    setLoad(true);
    if (btnAction === "add_subgroup") {
      await onClickAddSubgroup();
    }
    setLoad(false);
  };

  return (
    <Button
      target={btnAction === "external_link" ? "_blank" : "_self"}
      href={btnAction === "external_link" ? btnLink : ""}
      onClick={handleClick}
      disabled={loading}
      classes={{
        root: `InfoListFooter-root ${classes.InfoListFooter}`,
        label: classes.BtnLabel
      }}
      fullWidth
    >
      {loading ? (
        <CircularProgress className={classes.Loading} />
      ) : (
        btnIcon && <Icon className={classes.BtnIcon}>{btnIcon}</Icon>
      )}
      {btnText && (
        <p className={`BtnText-root ${classes.BtnText}`}>{btnText}</p>
      )}
    </Button>
  );
};

InfoListFooter.defaultProps = {
  btnText: "",
  btnIcon: undefined,
  btnAction: "external_link",
  btnLink: "",
  onClickAddSubgroup: () => {}
};

InfoListFooter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired, // https://material-ui.com/pt/styles/advanced/#withstyles
  btnText: PropTypes.string,
  btnIcon: PropTypes.string,
  btnAction: PropTypes.string,
  btnLink: PropTypes.string,
  onClickAddSubgroup: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(InfoListFooter);
