import { combineReducers } from "redux";
import {
  jsonFormReducer,
  renderInfosReducer,
  saveJsonForm
} from "./jsonFormReducer";
import { schoolDataReducer } from "./schollDataReducer";

export const Reducers = combineReducers({
  jsonFormState: jsonFormReducer,
  renderInfoState: renderInfosReducer,
  jsonForm: saveJsonForm,
  schoolData: schoolDataReducer
});
